import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { area_zone_url } from './Zones';
import { ServicesZone } from '../../../../../services/api/ServicesAera';
import { ServicesClients } from '../../../../../services/api/ServicesClients';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const UpdateZones = () => {

    const { id_zone } = useParams();
    const [client, setClient] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [areaZone, setareaZone] = useState({
        id: id_zone,
        area_zone_target: "",
        area_zone_description: "",
        area_zone_client: null, // id client
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setareaZone({
            ...areaZone,
            [name]: value
        });
    }

    useEffect(() => {
        document.title = "Area - Zone";
        getClients();
        getZoneById();
    }, []);

    const getZoneById = async () => {
        await ServicesZone.detail(id_zone).then((res) => {
            const data = res.data.data.zone;   
            setareaZone({
                area_zone_target: data.area_zone_target && data.area_zone_target,
                area_zone_description: data.area_zone_description && data.area_zone_description,
                area_zone_client: data.area_zone_client && data.area_zone_client,
            });
        }).catch((err) => {
            return err
        });
    }

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };
    const UpdateZones = async (e) => {
        e.preventDefault();

        setLoading(true); 
        
        await ServicesZone.update(id_zone, JSON.stringify(areaZone)).then((res) => {
            navigate(area_zone_url);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="admin-area-zone-update" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={area_zone_url} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update Zone</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => UpdateZones(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client</label>
                        <select className={`form-select`} onChange={handleOnChange} value={areaZone.area_zone_client} name='area_zone_client' >
                            <option>Select client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${areaZone.area_zone_client === "" || areaZone.area_zone_client === "Select client" || areaZone.area_zone_client === null ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Target</label>
                        <input onChange={handleOnChange} name='area_zone_target' value={areaZone.area_zone_target} type="text" className="form-control" placeholder="" />
                        <small className={`${areaZone.area_zone_target === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Description </label>
                        <textarea onChange={handleOnChange} className={`form-control`} name='area_zone_description' value={areaZone.area_zone_description} rows={3} defaultValue={""} />
                        <small className={`${areaZone.area_zone_description === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate(area_zone_url) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
