import React, { Fragment, useEffect, useState } from 'react';
import { MdEdit, MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesTargets } from '../../../../services/api/ServicesTargets';
import { FaDownload } from 'react-icons/fa';
import { baseURLDownloadsExcelFile, downloadsExcelFile_target } from '../../../../services/BASE_URL';
import { SpinnerData } from './../../../../components/SpinnerData';
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../components/Button';
import { HiOutlineDocumentSearch } from 'react-icons/hi';
import { ModelTemplapleSimple } from '../../../../components/CustomModal'; 


export const Targets = () => {


    const [targets, setTargets] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [filterYearMonth, setFilterYearMonth] = useState({
        filterMonth: "",
        filterYear: "",
    });
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });

    const monthList = ['January', 'February', 'March', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const yearList = ['2023', '2024', '2025', '2026', '2027', '2028', '2029'];


    useEffect(() => {
        document.title = "Target";
    });

    useEffect(() => {
        getTargets();
        getFilterMonthYear();
    }, []);

    // Get all targets
    const getTargets = async () => {
        await ServicesTargets.get().then((res) => {
            setTargets(res.data.Target);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const handleOnChange = (e) => {
        setFilterYearMonth({
            ...filterYearMonth,
            [e.target.name]: e.target.value
        });
    }

    const deleteTargets = async (id) => {
        setLoading(true);
        await ServicesTargets.delete(id).then((res) => {
            setShowModalDelete(false);
            getTargets();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }

    const getFilterMonthYear = async () => {
        await ServicesTargets.get_excel_file('2023', 'janvier').then((res) => {
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
    };

    let modems = 0;
    let routers = 0;
    let airtel_money = 0;

    const count_modems = () => {
        targets.forEach((item) => {
            modems += item.target_moderm;
        });
        return modems;
    };

    const count_routers = () => {
        targets.forEach((item) => {
            routers += item.target_routeurs;
        });
        return routers;
    };

    const count_airtel_money = () => {
        targets.forEach((item) => {
            airtel_money += item.target_airtelmoney;
        });
        return airtel_money;
    };

    const total_count_modems = count_modems();
    const total_count_routers = count_routers();
    const total_count_airtel_money = count_airtel_money();


    return (
        <>
            <section id='admin-targets'>
                <div className='grid-custom-one-column'>
                    <h4 className='title'>Targets  par  zone</h4>
                    <div className='custom-btn-header custom-end'>
                        <a href={baseURLDownloadsExcelFile + downloadsExcelFile_target} download={`excel-file.xlsx`} className='link-btn-custom mx-2'>
                            <button type="button" className="btn-custom-green-icon me-2">
                                <FaDownload className='icon' />
                                <span>Excel file </span>
                            </button>
                        </a>
                        <div className="mx-2">
                            <select className="form-select" aria-label="">
                                <option>Selected country</option>
                                {
                                    monthList.map((item, index) =>
                                        <Fragment key={`unique-${index}`}>
                                            <option value={item}>{item}</option>
                                        </Fragment>
                                    )
                                }
                            </select>
                        </div>
                        <div className="mx-2">
                            <select className="form-select" onChange={handleOnChange} name='target_countrie' aria-label="">
                                <option>Selected year</option>
                                {
                                    yearList.map((item, index) =>
                                        <Fragment key={`unique-${index}`}>
                                            <option value={item}>{item}</option>
                                        </Fragment>
                                    )
                                }
                            </select>
                        </div>
                        <a href={baseURLDownloadsExcelFile + downloadsExcelFile_target} download={`excel-file.xlsx`} className='link-btn-custom mx-2'>
                            <button type="button" className="btn-custom-green-icon">
                                <HiOutlineDocumentSearch className='icon' />
                                <span>Search excel file </span>
                            </button>
                        </a>
                        <Link to='/admin/targets/add' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add a zone`} />
                        </Link>
                    </div>
                </div>
                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Clients</th>
                                <th scope="col">ZONE</th>
                                <th scope="col">Month</th>
                                <th scope="col">Modems</th>
                                <th scope="col">Routers</th>
                                <th scope="col">Airtel Money</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${targets.length !== 0 && 'd-none'}`}>
                                <td colSpan={8} className={`${!loadData && "custom-loading-data-td"} custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                </td>
                            </tr>
                            {
                                !loadData && targets.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <tr>
                                            <td>{item.target_countrie && item.target_countrie.country_name}</td>
                                            <td>{item.target_client && item.target_client.client_name}</td>
                                            <td>{item.target_zone && item.target_zone}</td>
                                            <td>{item.target_month && item.target_month}</td>
                                            <td>{item.target_moderm && item.target_moderm}</td>
                                            <td>{item.target_routeurs && item.target_routeurs}</td>
                                            <td>10,000</td>
                                            <td className="td-action">
                                                <Link to={`/admin/targets/edit/${item.id_target}`} className='td-link edit'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete' onClick={() => deleteData(item.id_target, item.target_client.client_name)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                )
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Total : </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{total_count_modems}</td>
                                <td>{total_count_routers}</td>
                                <td>{total_count_airtel_money}</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name} {showModalDeleteData.id}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteTargets(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
