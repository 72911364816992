import React from 'react';
import { Outlet } from 'react-router-dom';
import { MenuContentBolderLighter } from '../../../../components/sidebar/SideBar';

export const AreaLayouts = () => {
        
    const url = "/admin/area/";

    const menu = [
        {
            title: "Area",
            path: "list",
            sub_navbar: null,
            location_path: url,
        }, 
        {
            title: "Zone",
            path: "zone",
            sub_navbar: null,
            location_path: url,
        }, 
        {
            title: "Territories",
            path: "territories",
            sub_navbar: null,
            location_path: url,
        }, 
        {
            title: "Cellules",
            path: "cellules",
            sub_navbar: null,
            location_path: url,
        }, 
        {
            title: "Cite",
            path: "cite",
            sub_navbar: null,
            location_path: url,
        }, 
        {
            title: "Site",
            path: "site",
            sub_navbar: null,
            location_path: url,
        }, 
    ];

    return (
        <>

            <section id='admin-area-layouts'>
                <nav className='menu-area'>
                    <MenuContentBolderLighter fontionnality={`Traning`} lighter={true} bolder={false} menu={menu} />
                </nav>
                <Outlet />
            </section>
        </>
    );
};

