import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Area } from '../views/areas/Area';
import { CreateArea } from '../views/areas/Create'; 
import { AreaLayouts } from '../views/AreaLayouts';
import { Cellules } from '../views/cellules/Cellules';
import { Zones } from '../views/zone/Zones';
import { CreateZones } from '../views/zone/Create';
import { UpdateZones } from '../views/zone/Update';
import { Territories } from '../views/territories/Territories';
import { CreateTerritories } from '../views/territories/Create';
import { UpdateTerritories } from '../views/territories/Update';
import { CreateCellules } from '../views/cellules/Create';
import { UpdateCellules } from '../views/cellules/Update';
import { Cites } from '../views/cite/Cites';
import { CreateCites } from '../views/cite/Create';
import { UpdateCites } from '../views/cite/Update';
import { Sites } from '../views/site/Sites'; 
import { CreateSites } from '../views/site/Create';
import { UpdateSites } from '../views/site/Update';

export const AreaControllers = () => {
    return (
        <>
            <Routes>
                <Route exact  element={<AreaLayouts />} >
                    <Route exact path='list/' >
                        <Route exact path='' element={<Area />} />
                        <Route exact path='create' element={<CreateArea />} />
                        {/* <Route exact path='update/id_zone' element={<CreateArea />} /> */}
                    </Route>
                    <Route exact path='zone/' >
                        <Route exact path='' element={<Zones />} />
                        <Route exact path='create' element={<CreateZones />} />
                        <Route exact path='update/:id_zone' element={<UpdateZones />} />
                    </Route>
                    <Route exact path='territories/' >
                        <Route exact path='' element={<Territories />} />
                        <Route exact path='create' element={<CreateTerritories />} />
                        <Route exact path='update/:id_territories' element={<UpdateTerritories />} />
                    </Route>
                    <Route exact path='cellules/' >
                        <Route exact path='' element={<Cellules />} />
                        <Route exact path='create' element={<CreateCellules />} />
                        <Route exact path='update/:id_cellules' element={<UpdateCellules />} />
                    </Route>
                    <Route exact path='cite/' >
                        <Route exact path='' element={<Cites />} />
                        <Route exact path='create' element={<CreateCites />} />
                        <Route exact path='update/:id_cite' element={<UpdateCites />} />
                    </Route>
                    <Route exact path='site/' >
                        <Route exact path='' element={<Sites />} />
                        <Route exact path='create' element={<CreateSites />} />
                        <Route exact path='update/:id_site' element={<UpdateSites />} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
};