import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServicesUsers } from '../../../../services/api/ServicesUsers';
import { ServicesSettingRoles, ServicesSettingsCounties } from '../../../../services/api/ServicesSettings';
import { ServicesClients } from '../../../../services/api/ServicesClients';
import { KCustomButtonDouble } from '../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const UpdateUsers = () => {

    const { id_users } = useParams();
    const navigate = useNavigate();
    const [roles, setRoles] = useState([]);
    const [client, setClient] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [userstUpdate, setUserstUpdate] = useState({
        the_client: "",
        country: "",
        username: "",
        email: "",
        password: "",
        role: "",
    });

    useEffect(() => {
        document.title = "Users/Edit";
    });


    const handleOnChange = (e) => {
        setUserstUpdate({
            ...userstUpdate,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        getCountries();
        getClients();
        getUsersById();
        getRoles();
    }, []);

    const getUsersById = async () => {
        await ServicesUsers.getById(id_users).then((res) => {
            // console.log(res);
            const data = res.data.data;
            setUserstUpdate({
                the_client: data.the_client && data.the_client.id_client,
                country: data.country && data.country.id_country,
                username: data.username,
                email: data.email,
                privilege: data.privilege && data.privilege,
                role: data.role && data.role.id,
            });
        }).catch((err) => {
            return err
        });
    }

    // Get all roles
    const getRoles = async () => {
        await ServicesSettingRoles.get().then((res) => {
            setRoles(res.data.data);
        }).catch((err) => {
            return err
        })
    };

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };


    const updateUsers = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_client', id_users)
        forms.append('the_client', Number(userstUpdate.the_client))
        forms.append('country', Number(userstUpdate.country))
        forms.append('username', userstUpdate.username)
        forms.append('email', userstUpdate.email)
        forms.append('password', userstUpdate.password)
        forms.append('role', userstUpdate.role)

        await ServicesUsers.put(id_users, forms).then((res) => {
            navigate('/admin/users');
            toast.success(res.data.message)
        }).catch((err) => {
        });

        setLoading(false);
    };


    return (
        <>
            <section id="admin-users-update" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/users`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update user</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => updateUsers(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} onChange={handleOnChange} value={userstUpdate.the_client} name='the_client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${userstUpdate.the_client === '' || userstUpdate.the_client === "Selected client" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='country' value={userstUpdate.country} >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${userstUpdate.country === "" || userstUpdate.country === "Selected country" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">User's role</label>
                        <select className={`form-select`} onChange={handleOnChange} value={userstUpdate.role} name='role' >
                            <option>Selected role</option>
                            {
                                roles.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.role_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.role && (errors.role[0] || userstUpdate.role === "" || userstUpdate.role === "Selected role") ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Email</label>
                        <input onChange={handleOnChange} name='email' value={userstUpdate.email} type="email" className="form-control" placeholder="" />
                        <small className={`${userstUpdate.email === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Username</label>
                        <input onChange={handleOnChange} name='username' type="text" value={userstUpdate.username} className="form-control" placeholder="" />
                        <small className={`${errors.username && (errors.username[0] || userstUpdate.username === '') ? "errors-fields" : "d-none"}`}>{errors.username}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Password</label>
                        <input onChange={handleOnChange} name='password' type="password" className="form-control" placeholder="" />
                        <small className={`${errors.password && (errors.password[0] || userstUpdate.password === '') ? "errors-fields" : "d-none"}`}>{errors.password}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate("/admin/users") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};

