import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { ServicesSettingRoles, ServicesSettingsCounties, ServicesSettingsLevelOfStudy } from '../../../../services/api/ServicesSettings';
import { ServicesClients } from '../../../../services/api/ServicesClients';
import { ServicesUsers } from '../../../../services/api/ServicesUsers';
import { KCustomButtonDouble } from '../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const CreateUsers = () => {

    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState([]);
    const [levelOfStudy, setLevelOfStudy] = useState([]);
    const [errors, setErrors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();
    const [usersPost, setUsersPost] = useState({
        the_client: "",
        country: "",
        username: "",
        email: "",
        password: "",
        roles: "",
    })

    const handleOnChange = (e) => {
        setUsersPost({
            ...usersPost,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        getCountries();
        getRoles();
        getClients();
        getLevelOfStudy_();
    }, []);

    // Get all level of study
    const getLevelOfStudy_ = async () => {
        await ServicesSettingsLevelOfStudy.get().then((res) => {
            setLevelOfStudy(res.data.Level_Study);
        }).catch((err) => {
            return err
        })
    };

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };

    // Get all privilege
    const getRoles = async () => {
        await ServicesSettingRoles.get().then((res) => {
            setRoles(res.data.data);
        }).catch((err) => {
            return err
        })
    };

    // console.log('data', usersPost);

    const createUsers = async (e) => {
        e.preventDefault();

        setLoading(true);
        const data = {
            country: Number(usersPost.country),
            email: usersPost.email,
            password : usersPost.password,
            role : Number(usersPost.roles),
            the_client : Number(usersPost.the_client),
            username : usersPost.username,
        }; 

        await ServicesUsers.post(JSON.stringify(data)).then((res) => {
             navigate('/admin/users');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };



    return (
        <>
            <section id="admin-users-create" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/users`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add user</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => createUsers(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} onChange={handleOnChange} name='the_client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${usersPost.the_client === '' || usersPost.the_client === "Selected client" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='country' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <option value={item.id_country}>{item.country_name}</option>
                                )
                            }
                        </select>
                        <small className={`${usersPost.country === "" || usersPost.country === "Selected country" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">User's role</label>
                        <select className={`form-select`} onChange={handleOnChange} name='roles' >
                            <option>Selected role</option>
                            {
                                roles.map((item, index) =>
                                    <option value={item.id}>{item.role_name}</option>
                                )
                            }
                        </select>
                        <small className={`${usersPost.roles === "" || usersPost.roles === "Selected role" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Email</label>
                        <input onChange={handleOnChange} name='email' type="email" className="form-control" placeholder="" />
                        <small className={`${usersPost.email === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Username</label>
                        <input onChange={handleOnChange} name='username' type="text" className="form-control" placeholder="" />
                        <small className={`${errors.username && (errors.username[0] || usersPost.username === '') ? "errors-fields" : "d-none"}`}>{errors.username}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Password</label>
                        <input onChange={handleOnChange} name='password' type="password" className="form-control" placeholder="" />
                        <small className={`${errors.password && (errors.password[0] || usersPost.password === '') ? "errors-fields" : "d-none"}`}>{errors.password}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/users") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
