import React, { useEffect, useState } from 'react';
import { logo } from '../../../assets/images/ExportsImages'; 
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServiceSessions } from '../../../services/ServicesSession';
import { ServicesAuthentication } from '../../../services/api/ServicesAuths';
import { KCustomButtonSimaple } from '../../../components/Button';

export const Login = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);

    const [login, setLogin] = useState({
        username: "",
        password: "",
    })

    useEffect(() => {
        document.title = "Login";
    });

    const handleOnChange = (e) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        });
    }

    const LoginUser = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('username', login.username)
        forms.append('password', login.password)

        await ServicesAuthentication.sign_in(forms).then((res) => {
            if (res.status === 200) {
                setLoading(true);
                toast.success("Successful connection");
                let id = res.data.user.id;
                let is_superuser = res.data.user.is_superuser
                let name = res.data.user.nom
                let profile_picture = res.data.user.profile_picture
                let is_staff = res.data.user.is_staff
                let is_active = res.data.user.is_active
                let token = res.data.token
                let date_expiration = res.data.date_expiration
                let login_status = res.data.login_status
                ServiceSessions.saveAuth(id, is_superuser, name, profile_picture, is_staff, is_active, token, date_expiration, login_status);
            }
            navigate('/admin/homes');
        }).catch((err) => { 
            setErrors(err.response.data.errors);
            toast.error(err.response.data.login_status);
        });

        setLoading(false);
    };


    return (
        <>
            <section id='login'>
                <div className='content'>
                <div className='image'>
                    <img src={logo} width={100} height={100} alt='field360' />
                </div>
                    <form onSubmit={(e) => LoginUser(e)} className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className="">
                            <label htmlFor="" className="form-label"> Nom d'utilisateur </label>
                            <input type="text" className="form-control" onChange={handleOnChange} name='username' placeholder="" />
                        </div>
                        <div className="">
                            <label htmlFor="" className="form-label"> Mot de passe </label>
                            <input type="password" onChange={handleOnChange} name='password' className="form-control" placeholder="" />
                        </div>
                        <KCustomButtonSimaple loading={loading} texte={`Connexion`} type={`submit`} />
                    </form>
                </div>
            </section>
        </>
    );
};
