import React, { Fragment, useEffect, useState } from 'react'
import { ServicesTraningQuizAnswerSections, ServicesTraningSections } from '../../../../../../../../services/api/ServicesTrainings';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoChevronBackSharp } from 'react-icons/io5';
import { KCustomButtonDouble } from '../../../../../../../../components/Button';
import { ModelTemplapleSimple } from '../../../../../../../../components/CustomModal';
import { IoMdAdd, IoMdCloseCircle } from 'react-icons/io';
import { readFileAsBase64, urlToBase64, verifyBase64MediaImage, verifyBase64MediaVideo } from '../../../../../../../../components/ExtractDate';
import { baseURLMedia } from '../../../../../../../../services/BASE_URL';

export const EidtQuizSections = () => {

    const [showModalAddAnswer, setShowModalAddAnswer] = useState(false);
    const [showModalRemoveAnswer, setShowModalRemoveAnswer] = useState(false);

    const navigate = useNavigate();
    const { id_training, id_section, id_quiz } = useParams();
    const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([]);
    const [errors, setErrors] = useState([]);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });

    const [newAnswer, setNewAnswer] = useState({
        id_quiz: Number(id_section),
        answer_label: "",
        answer_correct: ""
    });

    const [edditQuizSection, setEditQuizSection] = useState({
        quiz_section: {
            id_section: Number(id_section),
            quiz_question_name: "",
            quiz_question_points: "",
            quiz_question_type: "",
            quiz_question_media: [],
            quiz_description: "",
        },
        answers: [],
    })

    const handleAddAnswer = () => {
        // Vérifiez que les champs ne sont pas vides
        if (newAnswer.answer_label.trim() === "" || newAnswer.answer_correct === "") {
            // Affichez un message d'erreur ou faites ce que vous préférez
            return;
        }

        // Convertissez la chaîne de caractères en booléen pour answer_correct
        const isCorrect = newAnswer.answer_correct === "true";

        // Ajoutez la nouvelle réponse au tableau answers
        setEditQuizSection((prevSection) => ({
            ...prevSection,
            answers: [...prevSection.answers, {
                id_quiz: Number(id_section),
                answer_label: newAnswer.answer_label,
                answer_correct: isCorrect
            }]
        }));

        // Réinitialisez l'état de la nouvelle réponse
        setNewAnswer({
            id_quiz: Number(id_section),
            answer_label: "",
            answer_correct: ""
        });

        setShowModalAddAnswer(false);
    };

    // Remove answer response
    const removeAnswer = (index) => {
        setEditQuizSection((prevSection) => {
            const updatedAnswers = [...prevSection.answers];
            if (index >= 0 && index < updatedAnswers.length) {
                updatedAnswers.splice(index, 1);
            }
            return {
                ...prevSection,
                answers: updatedAnswers
            };
        });
    };

    const handleRemoveAnswer = (index) => {
        removeAnswer(index);
        setShowModalRemoveAnswer(false)
    };

    useEffect(() => {
        getSectionsById();
    }, []);


    // Get id sections
    const getSectionsById = async () => {
        await ServicesTraningSections.getById(id_section).then((res) => {
            setSection(res.data.data);
        }).catch((err) => {
            return err
        })
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        if (name === "answer_label" || name === "answer_correct") {
            // Si l'événement provient des champs du modal, mettez à jour newAnswer
            setNewAnswer({
                ...newAnswer,
                [name]: value
            });
        } else {
            setEditQuizSection((prevSection) => ({
                ...prevSection,
                quiz_section: {
                    ...prevSection.quiz_section,
                    [name]: value
                }
            }));
        }
    };

    useEffect(() => {
        getTraningsQuizById();
    }, []);


    // ================================================================================
    // ---------- Convertir automatiquement la media du format json de l'API ----------
    // ================================================================================
    const getTraningsQuizById = async () => {
        await ServicesTraningQuizAnswerSections.getById(id_quiz).then((res) => { 
            const data = res.data.quiz_section;
            convertUnconvertedMedia(data.quiz_question_media).then((convertedMedia) => {
                setEditQuizSection((item) => ({
                    ...item,
                    quiz_section: {
                        ...item.quiz_section,
                        id_section: Number(id_section),
                        quiz_question_name: data.quiz_question_name,
                        quiz_question_points: data.quiz_question_points,
                        quiz_question_type: data.quiz_question_type,
                        quiz_question_media: convertedMedia,
                        quiz_description: data.quiz_description,
                    },
                    answers: data.answers,
                }));
            });
        }).catch((err) => {
            console.error('Erreur lors de la récupération des chapitres', err);
        });
    } 
    const convertUnconvertedMedia = async (media) => {
        const updatedMedia = await Promise.all(media.map(async (item) => {
            if (item.media_file && !item.media_file.startsWith('data:image') && !item.media_file.startsWith('data:video')) {
                // Si le fichier n'est pas déjà en base64, le convertir
                return new Promise((resolve) => {
                    urlToBase64(`${baseURLMedia}${item.media_file}`, (err, base64) => {
                        if (err) {
                            console.error('Erreur lors de la conversion du fichier en base64', err);
                            resolve(item); // Retourne l'élément sans modification en cas d'erreur
                        } else {
                            resolve({ ...item, media_file: base64 });
                        }
                    });
                });
            } else {
                // Si le fichier est déjà en base64, le retourner tel quel
                return item;
            }
        }));
        return updatedMedia;
    };

    const handleFileOnChange = async (e) => {
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            const fileType = files[i].type.split('/')[0];
            const isImageOrVideo = fileType === 'image' || fileType === 'video'; 
            if (isImageOrVideo) {
                const base64Data = await readFileAsBase64(files[i]);
                setEditQuizSection((item) => ({
                    ...item,
                    quiz_section: {
                        ...item.quiz_section,
                        quiz_question_media: [
                            ...item.quiz_section.quiz_question_media,
                            { media_file: base64Data }
                        ]
                    }
                }))
            } else {
                console.error(`Unsupported file type: ${fileType}`);
            }
        }
    };

    // Remove media
    const removeMedia = (index) => {
        setEditQuizSection((item) => {
            const updatedMedia = [...item.quiz_section.quiz_question_media];
            if (index >= 0 && index < updatedMedia.length) {
                updatedMedia.splice(index, 1);
            } else {
                console.error(`Index ${index} out of bounds.`);
            }
            return {
                ...item,
                quiz_section: {
                    ...item.quiz_section,
                    quiz_question_media: updatedMedia
                }
            };
        });
    };

    const updateQuizSection = async (e) => {
        e.preventDefault();
        setLoading(true);

        var data = {
            quiz_section: {
                id_section: Number(id_section),
                quiz_question_name: edditQuizSection.quiz_section.quiz_question_name,
                quiz_question_points: Number(edditQuizSection.quiz_section.quiz_question_points),
                quiz_question_type: edditQuizSection.quiz_section.quiz_question_type,
                quiz_question_media: edditQuizSection.quiz_section.quiz_question_media,
                quiz_description: edditQuizSection.quiz_section.quiz_description
            },
            answers: edditQuizSection.answers
        }

        await ServicesTraningQuizAnswerSections.put(id_quiz, JSON.stringify(data)).then((res) => {
            navigate(`/admin/trainings/${id_training}/section/${id_section}/quiz`);
            toast.success("section and quiz create with successfull");
        }).catch((err) => {
            setErrors(err.response.data);
            toast.error("Tous les champs sont requis");
        });

        setLoading(false);
    };


    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalRemoveAnswer(true);
    }


    return (
        <>
            <section id="admin-section-forms-edit" className='admin-traning-form-all'>
                <div className='cusom-go-back-page mb-3'>
                    <Link to={`/admin/trainings/${id_training}/section/${id_section}/quiz`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Edit  quiz</h4>
                    </div>
                </div>

                <form className='custom-form' onSubmit={(e) => updateQuizSection(e)}>
                    <div className='body-formulaire'>
                        <div className="mb-3">
                            <label className="form-label"> Name : </label>
                            <input type="text" className={`form-control`} onChange={handleOnChange} value={edditQuizSection.quiz_section.quiz_question_name} name='quiz_question_name' />
                            <small className={`${errors.quiz_question_name && (errors.quiz_question_name[0] || edditQuizSection.quiz_section.quiz_question_name === '') ? "errors-fields" : "d-none"}`}>{errors.quiz_question_name}</small>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="quiz_description" className="form-label"> Description : </label>
                            <textarea id="quiz_description" value={edditQuizSection.quiz_section.quiz_description} className={`form-control`} onChange={handleOnChange} name="quiz_description" rows="4"></textarea>
                            <small className={`${errors.quiz_description && (errors.quiz_description[0] || edditQuizSection.quiz_section.quiz_description === '') ? "errors-fields" : "d-none"}`}>{errors.quiz_description}</small>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="quiz_question_points" className="form-label"> Number of points </label>
                            <input type="number" onChange={handleOnChange} value={edditQuizSection.quiz_section.quiz_question_points} name='quiz_question_points' className={`form-control`} id="quiz_question_points" />
                            <small className={`${edditQuizSection.quiz_section.quiz_question_points === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Question Type </label>
                            <select className={`form-select`} value={edditQuizSection.quiz_section.quiz_question_type} onChange={handleOnChange} name='quiz_question_type'>
                                <option>Select type</option>
                                <option value={`multiple`}>Multiple choice</option>
                                <option value={`unique`}>Unique answer</option>
                            </select>
                            <small className={`${edditQuizSection.quiz_section.quiz_question_type === "" || edditQuizSection.quiz_section.quiz_question_type === "Select type" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="quiz_question_media" className="form-label">Add media </label>
                            <input onChange={handleFileOnChange} name='quiz_question_media' multiple type="file" className={`form-control`} id="quiz_question_media" />
                            <small className={`${edditQuizSection.quiz_section.quiz_question_media === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                        <div className='custom-display-media-without-description'>
                            {
                                edditQuizSection.quiz_section && edditQuizSection.quiz_section.quiz_question_media.map((item, index) => (
                                    // <Fragment key={index}>
                                    //     <div className='content'>
                                    //         <IoMdCloseCircle className='icon-close' onClick={() => removeMedia(index)} />
                                    //         <img src={item.media_file} alt="Base64 Images" onClick={() => console.log(index)} />
                                    //     </div>
                                    // </Fragment>
                                    <Fragment key={index}>
                                        <div className='content'>
                                            <IoMdCloseCircle className='icon-close' onClick={() => removeMedia(index)} />
                                            {
                                                verifyBase64MediaImage(item.media_file) && (
                                                    <>
                                                        <img src={`${item.media_file}`} title='Cliquer pour cacher la description' alt="Base64 Images" onClick={() => { }} />
                                                    </>
                                                )
                                            }
                                            {
                                                verifyBase64MediaVideo(item.media_file) && (
                                                    <>

                                                        <video controls>
                                                            <source src={`${item.media_file}`} />
                                                            Votre navigateur ne supporte pas la balise vidéo.
                                                        </video>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Fragment>
                                ))
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Section </label>
                            <input value={section.sections_name} type="text" className={`form-control`} disabled />
                        </div>
                        <div className="my-4 align-items-center">
                            <span className='header-choise d-block pb-2' style={{ fontSize: "1.1rem", color: "black" }}>Option de réponses </span>
                        </div>
                        <div className="content-option-answers">
                            {
                                edditQuizSection.answers.map((item, index) => (
                                    <Fragment key={`unique-${index}`}>
                                        <span className={`${item.answer_correct ? 'correct' : 'error'} answer`} onClick={() => deleteData(index, item.answer_label)}>{item.answer_label} </span>
                                    </Fragment>
                                ))
                            }
                        </div>
                        <Link to="#" className='link-add-option' onClick={() => setShowModalAddAnswer(true)}>
                            <span className=''>
                                <IoMdAdd size={15} className='icon' />
                            </span>
                            <span className='description-btn ms-3'>Add a new response option</span>
                        </Link>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate(`/admin/trainings/${id_training}/section/${id_section}/quiz`) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>

            </section>

            {/* Add answer option */}
            <ModelTemplapleSimple icon={true} show={showModalAddAnswer} hide={() => { setShowModalAddAnswer(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal traning-custom-add-answer'>
                        <div className='content-body'>
                            <h5 className='header'>Add answer</h5>
                            <form className=''>
                                <div className="">
                                    <label htmlFor="chapter_name" className="form-label"> Name </label>
                                    <input type="text" className={`form-control`} onChange={handleOnChange} name='answer_label' />
                                    <small className={`${newAnswer.answer_label === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                                </div>
                                <div className="">
                                    <label className="form-label">Correct  </label>
                                    <select className={`form-select`} onChange={handleOnChange} name='answer_correct'>
                                        <option>Select answer true or false</option>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                    <small className={`${newAnswer.answer_correct === '' || newAnswer.answer_correct === 'Select answer true or false' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                                </div>
                            </form>
                        </div>
                        {/* Submit  ---- onClick={handleAddAnswer} */}
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalAddAnswer(false) }}
                            clickr={handleAddAnswer}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-training-add-optin-answer'} />


            {/* Remove answer option */}
            <ModelTemplapleSimple icon={true} show={showModalRemoveAnswer} hide={() => { setShowModalRemoveAnswer(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalRemoveAnswer(false) }}
                            clickr={() => handleRemoveAnswer(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} />
        </>
    )
}
