import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServicesSite, ServicesTerritories } from '../../../../../services/api/ServicesAera';
import { area_site_url } from './Sites';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';


export const UpdateSites = () => {

    const { id_site } = useParams();
    const [areaTerritories, setAreaTerritories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [areaSites, setareaSites] = useState({
        id: id_site,
        area_site_id: 0,
        area_site_name: "",
        area_site_description: "",
        area_site_territorys: null, // id territoriy
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setareaSites({
            ...areaSites,
            [name]: value
        });
    }

    useEffect(() => {
        document.title = "Area - Zone";
        getAreaTerritories();
        getSiteById();
    }, []);

    const getSiteById = async () => {
        await ServicesSite.detail(id_site).then((res) => {
            const data = res.data.data.Sites; 
            setareaSites({
                area_site_name: data.area_site_name && data.area_site_name,
                area_site_description: data.area_site_description && data.area_site_description,
                area_site_territorys: data.area_site_territorys && data.area_site_territorys,
            });
        }).catch((err) => {
            return err
        });
    }

    // Get all area territories
    const getAreaTerritories = async () => {
        await ServicesTerritories.get().then((res) => {
            setAreaTerritories(res.data.data);
        }).catch((err) => {
            return err
        })
    };

    const UpdateSites = async (e) => {
        e.preventDefault();

        setLoading(true);

        await ServicesSite.update(id_site, JSON.stringify(areaSites)).then((res) => {
            navigate(area_site_url);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };
 

    return (
        <>
            <section id="admin-area-sites-update" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={area_site_url} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update Sites</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => UpdateSites(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Territory</label>
                        <select className={`form-select`} onChange={handleOnChange} value={areaSites.area_site_territorys} name='area_site_territorys' >
                            <option>Select territory</option>
                            {
                                areaTerritories.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.area_territory_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${areaSites.area_site_territorys === "" || areaSites.area_site_territorys === "Select territory" || areaSites.area_site_territorys === null ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Name</label>
                        <input onChange={handleOnChange} name='area_site_name' value={areaSites.area_site_name} type="text" className="form-control" placeholder="" />
                        <small className={`${areaSites.area_site_name === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Description </label>
                        <textarea onChange={handleOnChange} className={`form-control`} name='area_site_description' value={areaSites.area_site_description} rows={3} defaultValue={""} />
                        <small className={`${areaSites.area_site_description === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate(area_site_url) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
