import React, { Fragment, useEffect, useState } from 'react';
import { MdEdit, MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import { ServicesFootSoldiersKYC, ServicesFootSoldiersValidate } from '../../../../services/api/ServicesFieldSoldiers';
import { baseURLMedia } from '../../../../services/BASE_URL';
import { toast } from 'react-toastify';
import { SpinnerData } from '../../../../components/SpinnerData';
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../components/Button';
import { ModelTemplapleSimple } from '../../../../components/CustomModal'; 


export const ValidateKYC = () => {

    const [footSoldierValidate, setFootSoldierValidate] = useState([]);
    const [kycCount, setKycCount] = useState([]);
    const [kycAgence, setKycAgence] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });

    useEffect(() => {
        document.title = "Foot-Soldier";
    });


    useEffect(() => {
        getFootSoldiersValidate();
        getFootSoldiersPending();
    }, []);


    const getFootSoldiersValidate = async () => {
        await ServicesFootSoldiersValidate.get().then((res) => {
            setFootSoldierValidate(res.data.filtered_kyc);
            setKycCount(res.data.count_kyc);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const getFootSoldiersPending = async () => {
        await ServicesFootSoldiersKYC.get().then((res) => {
            setKycAgence(res.data.count_kyc);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };


    const delete_pvalidate_kyc = async (id) => {
        setLoading(true);
        await ServicesFootSoldiersKYC.delete(id).then((res) => {
            setShowModalDelete(false);
            getFootSoldiersValidate();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
            return err;
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }

    return (
        <>
            <section id='admin-fs'>
                <div className='grid-custom-one-column'>
                    <h4 className='title'>FootSoldiers validated</h4>
                    {
                        (!loadData && kycAgence !== 0 ) ? (
                            <div className='custom-btn-header custom-end'>
                                <>
                                    <Link to='/admin/foot-soldiers/pending' className='link-btn-custom'>
                                        <KCustomButtonSimaple loading={false} type={`button`} texte={<>Valider les demandes de KYC en attente <span className='fs-kyc'>({kycAgence})</span></>} />
                                    </Link>
                                </>
                            </div>
                        ) : <></>
                    }
                </div>
                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Clients</th>
                                <th scope="col">FullName</th>
                                <th scope="col">Zone</th>
                                <th scope="col">Phone number</th>
                                <th scope="col">Country</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${footSoldierValidate.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={`custom-center-loading`}> 
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && footSoldierValidate.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td> 
                            </tr>

                            {
                                !loadData && footSoldierValidate.map((item, index) => (
                                    <Fragment key={`unique-${index}`}>
                                        <tr key={`unique-${index}`}>
                                            <td>{((item.kycAgentid && item.kycAgentid.the_client) && item.kycAgentid.the_client.client_name) && item.kycAgentid.the_client.client_name}</td>
                                            <td>
                                                <div className=''>
                                                    <span>
                                                        <img src={`${baseURLMedia}${item.kycAgentid.photo_selfie}`} width={30} height={30} alt='artel' />
                                                    </span>
                                                    <span className='ms-2' >{item.kycAgentid && item.kycAgentid.nom} {item.kycAgentid && item.kycAgentid.prenoms}</span>
                                                </div>
                                            </td>
                                            <td>{item.kycAgentid && item.kycAgentid.localite && item.kycAgentid.localite.locality_name}</td>
                                            <td>{item.kycAgentid && item.kycAgentid.numero}</td>
                                            <td>{((item.kycAgentid && item.kycAgentid.country) && item.kycAgentid.country.country_name) && item.kycAgentid.country.country_name}</td>

                                            <td className="td-action">
                                                {/* <Link to={`#`} className='td-link edit'>
                                                     <MdEdit className='icon' />
                                                </Link> */}
                                                <Link to='' className='td-link delete' onClick={() => deleteData(item.id, `${item.kycAgentid.nom} ${item.kycAgentid.prenoms}`)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                    <>
                        <div className='grid-custom-one-column grid-gap-custom-normal'>
                            <div className='content-body'>
                                <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                            </div>
                            <KCustomButtonDouble
                                loading={loading}
                                textel={`Non`}
                                texter={`Oui`}
                                clickl={() => { setShowModalDelete(false) }}
                                clickr={() => delete_pvalidate_kyc(showModalDeleteData.id)}
                                typel={`button`}
                                typer={`button`} />
                        </div>
                    </>
                } className={'content'} modalClassName={'modal-delete-date-table'} /> 
            </section>
        </>
    );
};