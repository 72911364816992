import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServicesSettingsIndustries } from '../../../../../services/api/ServicesSettings';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const EditIndusty = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id_industry } = useParams();
    const [industryUpdate, setIndustryUpdate] = useState({
        industry_name: "",
    })

    useEffect(() => {
        getIndustriesById();
    }, []);

    const handleOnChange = (e) => {
        setIndustryUpdate({
            ...industryUpdate,
            [e.target.name]: e.target.value
        });
    }

    const getIndustriesById = async () => {
        await ServicesSettingsIndustries.getById(id_industry).then((res) => {
            const { industry_name } = res.data.data;
            setIndustryUpdate({
                industry_name,
            });
        }).catch((err) => {
            return err
        });
    }

    const updateIndustries = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_industry', id_industry)
        forms.append('industry_name', industryUpdate.industry_name)

        await ServicesSettingsIndustries.put(id_industry, forms).then((res) => {
            navigate('/admin/settings/industry');
            toast.success(res.data.message)
        }).catch((err) => {
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section className="admin-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/industry`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update industry</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => updateIndustries(e)}>
                    <div className="mb-3">
                        <label htmlFor="name_industry" className="form-label">Indistry name  :</label>
                        <input type="text" onChange={handleOnChange} name='industry_name' value={industryUpdate.industry_name} className={`form-control`} id="name_industry" />
                        <small className={`${industryUpdate.industry_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/users") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
