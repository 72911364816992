import React from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { IoChevronBackSharp } from 'react-icons/io5';
import { MenuContentBolderLighter } from '../../../../../../components/sidebar/SideBar';
export const LayoutsTranings = () => {

    const { id_training } = useParams();
    const url = `/admin/trainings/${id_training}/`;
    let location = useLocation().pathname;

    const menu = [
        {
            title: "Properties",
            path: "property/edit",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Course",
            path: "section",
            sub_navbar: "section/",
            location_path: url,
        },
        {
            title: "Final Test",
            path: "exam",
            sub_navbar: null,
            location_path: url,
        },
    ];


    return (
        <>
            <section id="admin-traning-edit">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/trainings`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    {
                        location.startsWith(`${url}property/edit`) && (
                            <>
                                <div className='grid-custom-one-column'>
                                    <h4 className='title'>Modification des propriétés</h4>
                                </div>
                            </>
                        )
                    }
                    {
                        location.startsWith(`${url}section`) && (
                            <>
                                <div className='grid-custom-one-column'>
                                    <h4 className='title'>Liste des sections</h4>
                                </div>
                            </>
                        )
                    }
                    {
                        location.startsWith(`${url}exam`) && (
                            <>
                                <div className='grid-custom-one-column'>
                                    <h4 className='title'>Test final</h4>
                                </div>
                            </>
                        )
                    }
                </div>
                <div className='menu-traning'>
                    <MenuContentBolderLighter fontionnality={`Traning`} lighter={false} bolder={true} menu={menu} />
                </div>
                <Outlet />
            </section>
        </>
    );
};
