import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServicesZone } from '../../../../../services/api/ServicesAera';
import { Link } from 'react-router-dom';
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { SpinnerData } from '../../../../../components/SpinnerData';
import { ModelTemplapleSimple } from '../../../../../components/CustomModal';

export const area_zone_url = `/admin/area/zone`;

export const Zones = () => {

    const [areaZone, setareaZone] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });


    useEffect(() => {
        document.title = "Area - Zone";
    });

    useEffect(() => {
        getAreaZone();
    }, []);

    // Get all area zones
    const getAreaZone = async () => {
        await ServicesZone.get().then((res) => {
            setareaZone(res.data.data);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const deleteAreaTerritories = async (id) => {
        setLoading(true);
        await ServicesZone.delete(id).then((res) => {
            setShowModalDelete(false);
            getAreaZone();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    } 

    return (
        <>
            <section id='admin-area-zone'>
                <div className='grid-custom-one-column'>
                    <div className='custom-btn-header custom-end'>
                        <Link to={`${area_zone_url}/create`} className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add`} />
                        </Link>
                    </div>
                </div>
                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Target</th>
                                <th scope="col">Description</th>
                                <th scope="col">Client</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${areaZone.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={`custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && areaZone.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td>
                            </tr>
                            {
                                !loadData && areaZone.map((item, index) => (
                                    <Fragment key={`unique-${index}`}>
                                        <tr key={index}>
                                            <td>{item.area_zone_target && item.area_zone_target}</td>
                                            <td>{item.area_zone_description && item.area_zone_description}</td>
                                            <td>{item.area_zone_client && item.area_zone_client.client_name}</td>
                                            <td>
                                                <td>
                                                    <td className="td-action">
                                                        <Link to={`${area_zone_url}/update/${item.id}`} className='td-link edit'>
                                                            <MdEdit className='icon' />
                                                        </Link>
                                                        <Link to='' className='td-link delete' onClick={() => deleteData(item.id, item.area_zone_target)}>
                                                            <MdDelete className='icon' />
                                                        </Link>
                                                    </td>
                                                </td>
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteAreaTerritories(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} />
        </>
    );
};
