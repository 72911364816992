import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoChevronBackSharp } from 'react-icons/io5';
import { ServicesSettingsCounties, ServicesSettingsLocality } from '../../../../../services/api/ServicesSettings';
import { ServicesClients } from '../../../../../services/api/ServicesClients';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { ServicesArea, ServicesTerritories } from '../../../../../services/api/ServicesAera';

export const CreateArea = () => {

    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState([]);
    const [localities, setLocalities] = useState([]);
    const [territories, setTerritories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [areaPost, setAreaPost] = useState({
        area_countrie: "",
        area_client: "",
        sheet_area: "",
        area_city_name: "",
        area_territory_name: "",
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setAreaPost({
            ...areaPost,
            [name]: value
        });
    }

    const handleFileOnChange = (e) => {
        setAreaPost({
            ...areaPost,
            [e.target.name]: e.target.files[0]
        });
    }

    useEffect(() => {
        getCountries();
        getClients();
        getLocality();
        getTerritory();
    }, []);

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    // Get all Territories 
    const getTerritory = async () => {
        await ServicesTerritories.get().then((res) => {
            setTerritories(res.data.data);
        }).catch((err) => {
            return err
        })
    };

    // Get all locality 
    const getLocality = async () => {
        await ServicesSettingsLocality.get().then((res) => {
            setLocalities(res.data.locality);
        }).catch((err) => {
            return err
        })
    };

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };


    const post_pos = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('area_countrie', Number(areaPost.area_countrie));
        forms.append('area_client', Number(areaPost.area_client));
        forms.append('sheet_area', areaPost.sheet_area);
        forms.append('area_city_name', Number(areaPost.area_city_name));
        forms.append('area_territory_name', Number(areaPost.area_territory_name));

        await ServicesArea.create(forms).then((res) => {
            navigate('/admin/area');
            var success = `${res.data.message} Delete ${res.data.deleted_entries} Update ${res.data.updated_entries} Create ${res.data.created_entries}`;
            toast.success(success)
        }).catch((err) => {
            setErrors(err.response.data)
            toast.error(err.response.data);
        });

        setLoading(false);
    };
 
    return (
        <>
            <section id="admin-area-create" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/area/list`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update Area</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => post_pos(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='area_countrie' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${areaPost.area_countrie === "Selected country" || areaPost.area_countrie === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} onChange={handleOnChange} name='area_client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${areaPost.area_client === "Selected client" || areaPost.area_client === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Locality </label>
                        <select className={`form-select`} onChange={handleOnChange} name='area_city_name' >
                            <option>Selected locality</option>
                            {
                                localities.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_locality}>{item.locality_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${areaPost.area_city_name === "Selected locality" || areaPost.area_city_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Territory </label>
                        <select className={`form-select`} onChange={handleOnChange} name='area_territory_name' >
                            <option>Selected territory</option>
                            {
                                territories.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.area_territory_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${areaPost.area_territory_name === "Selected locality" || areaPost.area_territory_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Excel file </label>
                        <input onChange={handleFileOnChange} name='sheet_area' type="file" className="form-control" placeholder="" />
                        <small className={`${errors.sheet_pos || areaPost.sheet_area === '' ? "errors-fields" : "d-none"}`}>{errors.sheet_pos}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/area/list") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    )
}
