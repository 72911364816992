import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoChevronBackSharp } from 'react-icons/io5';
import { area_cellules_url } from './Cellules';
import { ServicesCellules, ServicesSite } from '../../../../../services/api/ServicesAera';
import { KCustomButtonDouble } from '../../../../../components/Button';

export const CreateCellules = () => {

    const [areaSites, setareaSites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [areaCellules, setareaCellules] = useState({
        area_cellules_id: 0,
        area_cellule_name: "",
        area_cellule_description: "",
        area_cellule_site: null, // id site
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setareaCellules({
            ...areaCellules,
            [name]: value
        });
    }

    useEffect(() => {
        document.title = "Area - Cellules";
        getAreaSites();
    }, []);

    // Get all area site
    const getAreaSites = async () => {
        await ServicesSite.get().then((res) => {
            setareaSites(res.data.data);
        }).catch((err) => {
            return err
        })
    };

    const CreateCellules = async (e) => {
        e.preventDefault();

        setLoading(true);

        await ServicesCellules.create(JSON.stringify(areaCellules)).then((res) => {
            navigate(area_cellules_url);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };


    return (
        <>
            <section id="admin-area-cellule-create" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={area_cellules_url} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add Cellule</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => CreateCellules(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Site</label>
                        <select className={`form-select`} onChange={handleOnChange} value={areaCellules.area_cellule_site} name='area_cellule_site' >
                            <option>Select site</option>
                            {
                                areaSites.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.area_site_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${areaCellules.area_cellule_site === "" || areaCellules.area_cellule_site === "Select territory" || areaCellules.area_cellule_site === null ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Name</label>
                        <input onChange={handleOnChange} name='area_cellule_name' value={areaCellules.area_cellule_name} type="text" className="form-control" placeholder="" />
                        <small className={`${areaCellules.area_cellule_name === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Description </label>
                        <textarea onChange={handleOnChange} className={`form-control`} name='area_cellule_description' value={areaCellules.area_cellule_description} rows={3} defaultValue={""} />
                        <small className={`${areaCellules.area_cellule_description === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate(area_cellules_url) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
