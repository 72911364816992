import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServicesCite, ServicesTerritories } from '../../../../../services/api/ServicesAera';
import { area_territories_url } from './Territories';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';
import { da } from 'date-fns/locale';


export const UpdateTerritories = () => {

    const { id_territories } = useParams();
    const [areaCites, setareaCites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [areaTerritories, setAreaTerritories] = useState({
        id: id_territories,
        area_territory_name: "",
        area_territory_description: "",
        area_territory_city: null, // id cite
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setAreaTerritories({
            ...areaTerritories,
            [name]: value
        });
    }

    useEffect(() => {
        document.title = "Area - Territories";
        getAreaCites();
        getTerritoryById();
    }, []);

    const getTerritoryById = async () => {
        await ServicesTerritories.detail(id_territories).then((res) => {
            const data = res.data.data.Territorys; 
            setAreaTerritories({
                area_territory_name: data.area_territory_name && data.area_territory_name,
                area_territory_description: data.area_territory_description && data.area_territory_description,
                area_territory_city: data.area_territory_city && data.area_territory_city,
            });
        }).catch((err) => {
            return err
        });
    }

    // Get all area  cites 
    const getAreaCites = async () => {
        await ServicesCite.get().then((res) => {
            setareaCites(res.data.data);
        }).catch((err) => {
            return err
        })
    }; 

    const UpdateTerritories = async (e) => {
        e.preventDefault();

        setLoading(true);

        await ServicesTerritories.update(id_territories, JSON.stringify(areaTerritories)).then((res) => {
            navigate(area_territories_url);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };


    return (
        <>
            <section id="admin-area-territories-update" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={area_territories_url} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update Territories</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => UpdateTerritories(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">City</label>
                        <select className={`form-select`} onChange={handleOnChange} value={areaTerritories.area_territory_city} name='area_territory_city' >
                            <option>Select city</option>
                            {
                                areaCites.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.area_city_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${areaTerritories.area_territory_city === "" || areaTerritories.area_territory_city === "Select city" || areaTerritories.area_territory_city === null ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Name</label>
                        <input onChange={handleOnChange} name='area_territory_name' value={areaTerritories.area_territory_name} type="text" className="form-control" placeholder="" />
                        <small className={`${areaTerritories.area_territory_name === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Description </label>
                        <textarea onChange={handleOnChange} className={`form-control`} name='area_territory_description' value={areaTerritories.area_territory_description} rows={3} defaultValue={""} />
                        <small className={`${areaTerritories.area_territory_description === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate(area_territories_url) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
