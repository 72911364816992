import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesTranings } from '../../../../../services/api/ServicesTrainings';
import { ServicesSettingsCounties } from '../../../../../services/api/ServicesSettings';
import { ServicesClients } from '../../../../../services/api/ServicesClients';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const AddTraning = () => {

    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState([]);
    const [errors, setErrors] = useState([]);
    const [countries, setCountries] = useState([]);
    const navigate = useNavigate();
    const [postTraning, setaddTraning] = useState({
        training_name: "",
        training_onBoarding: "",
        training_min_score: "",
        training_description: "",
        training_mode: "",
        training_statut: "",
        training_category: "",
        id_client: "",
        countrie_id: "",
    })

    useEffect(() => {
        document.title = "Training/add";
    });


    const handleOnChange = (e) => {
        setaddTraning({
            ...postTraning,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        getCountries();
        getClients();
    }, []);

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };


    const createTranings = async (e) => {
        e.preventDefault(); 

        setLoading(true);

        const forms = new FormData();
        forms.append('training_name', postTraning.training_name)
        forms.append('training_min_score', postTraning.training_min_score)
        forms.append('training_min_score', postTraning.training_min_score)
        forms.append('training_description', postTraning.training_description)
        forms.append('training_mode', postTraning.training_mode)
        forms.append('training_statut', 0)
        forms.append('training_category', postTraning.training_category)
        forms.append('id_client', postTraning.id_client)
        forms.append('countrie_id', postTraning.countrie_id)

        await ServicesTranings.post(forms).then((res) => {
            navigate('/admin/trainings');
            toast.success(res.data.message)
        }).catch((err) => {
             setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });
        setLoading(false);
    };


    return (
        <>
            <section id="admin-traning-create" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/trainings`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add training</h4>
                    </div>
                </div>
                <form onSubmit={(e) => createTranings(e)} className='custom-form'>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Name :</label>
                        <input onChange={handleOnChange} type="text" name='training_name' className='form-control' />
                        <small className={`${errors.training_name || postTraning.training_name === '' ? "errors-fields" : "d-none"}`}>{errors.training_name}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Description :</label>
                        <textarea id="description" onChange={handleOnChange} className={`form-control`} name="training_description" rows="3" ></textarea>
                        <small className={`${errors.training_description || postTraning.training_description === '' ? "errors-fields" : "d-none"}`}>{errors.training_description}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Mode  : </label>
                        <select className={`form-select`} onChange={handleOnChange} name='training_mode' aria-label="Default select example">
                            <option>Selected mode</option>
                            <option value='0'>Inactif</option>
                            <option value='1'>Actif</option>
                        </select>
                        <small className={`${errors.training_mode && (postTraning.training_mode === "" || postTraning.training_mode === "Selected mode") ? "errors-fields" : "d-none"}`}>{errors.training_description}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Category :</label>
                        <input onChange={handleOnChange} type="text" name='training_category' className={`form-control ${errors.training_category && `erreur-field`}`} id="category" />
                        <small className={`${errors.training_category || postTraning.training_category ? "errors-fields" : "d-none"}`}>{errors.training_category}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} onChange={handleOnChange} name='id_client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <option value={item.id_client}>{item.client_name}</option>
                                )
                            }
                        </select>
                        <small className={`${postTraning.id_client === '' || postTraning.id_client === "Selected client" ? "errors-fields" : "d-none"}`}>{errors.id_client}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='countrie_id' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <option value={item.id_country}>{item.country_name}</option>
                                )
                            }
                        </select>
                        <small className={`${postTraning.countrie_id === "" || postTraning.countrie_id === "Selected country" ? "errors-fields" : "d-none"}`}>{errors.countrie_id}</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Onboarding : </label>
                        <select className={`form-select`} onChange={handleOnChange} name='training_onBoarding' aria-label="Default select example">
                            <option>selected Onboarding</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                        <small className={`${postTraning.training_onBoarding === '' || postTraning.training_onBoarding === "Selected Onboarding" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Assessement Required (%): </label>
                        <input type="number" onChange={handleOnChange} min={0} max={100} name="training_min_score" className={`form-control`} id="assesment" />
                        <small className={`${errors.training_min_score || postTraning.training_min_score === '' ? "errors-fields" : "d-none"}`}>{errors.training_min_score}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Validate`}
                        clickl={() => { navigate("/admin/trainings") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
