import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServicesTypeCampaign } from '../../../../../services/api/ServocesCampaigns';
import { Link } from 'react-router-dom';
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { SpinnerData } from '../../../../../components/SpinnerData';
import { ModelTemplapleSimple } from '../../../../../components/CustomModal';

export const campaign_types_url = `/admin/campaign/type`;

export const CampaignTypes = () => {
    
    const [campaignTypes, setCampaignTypes] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });

    useEffect(() => {
        document.title = "Campaign - Type";
    });

    useEffect(() => {
        getCampaignsTypes();
    }, []);

    // Get all campaign types
    const getCampaignsTypes = async () => {
        await ServicesTypeCampaign.get().then((res) => {
            setCampaignTypes(res.data.CampaignTypes);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const deleteCampaignTypes = async (id) => {
        setLoading(true);
        await ServicesTypeCampaign.delete(id).then((res) => {
            setShowModalDelete(false);
            getCampaignsTypes();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    } 

    return (
        <>
            <section id='admin-campaign-type-action'>
                <div className='grid-custom-one-column'>
                    <div className='custom-btn-header custom-end'>
                        <Link to={`${campaign_types_url}/create`} className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add`} />
                        </Link>
                    </div>
                </div>
                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                 <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${campaignTypes.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={`custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && campaignTypes.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td>
                            </tr>
                            {
                                !loadData && campaignTypes.map((item, index) => (
                                    <Fragment key={`unique-${index}`}>
                                        <tr key={index}>
                                            <td>{item.name && item.name}</td>
                                            <td>{item.description && item.description}</td>
                                             <td>
                                                <td className="td-action">
                                                    <Link to={`${campaign_types_url}/update/${item.id}`} className='td-link edit'>
                                                        <MdEdit className='icon' />
                                                    </Link>
                                                    <Link to='' className='td-link delete' onClick={() => deleteData(item.id, item.name)}>
                                                        <MdDelete className='icon' />
                                                    </Link>
                                                </td>
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteCampaignTypes(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} />
        </>
    );
};

