import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServicesCite, ServicesZone } from '../../../../../services/api/ServicesAera';
import { area_cite_url } from './Cites';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';


export const UpdateCites = () => {

    const { id_cite } = useParams();
    const [areaZone, setareaZone] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [areaSites, setareaSites] = useState({
        id: id_cite,
        area_city_name: "",
        area_city_description: "",
        area_city_zone: null, // id zone
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setareaSites({
            ...areaSites,
            [name]: value
        });
    }

    useEffect(() => {
        document.title = "Area - Cites";
        getAreaZone();
        getCiteById();
    }, []);

    const getCiteById = async () => {
        await ServicesCite.detail(id_cite).then((res) => {
            const data = res.data.data.City;   
            setareaSites({
                area_city_name: data.area_city_name && data.area_city_name,
                area_city_description: data.area_city_description && data.area_city_description,
                area_city_zone: data.area_city_zone && data.area_city_zone,
            });
        }).catch((err) => {
            return err
        });
    }    

    // Get all area zones
    const getAreaZone = async () => {
        await ServicesZone.get().then((res) => {
            setareaZone(res.data.data);
        }).catch((err) => {
            return err
        })
    };
    const UpdateCites = async (e) => {
        e.preventDefault();

        setLoading(true);

        await ServicesCite.update(id_cite, JSON.stringify(areaSites)).then((res) => {
            navigate(area_cite_url); 
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };


    return (
        <>
            <section id="admin-area-cites-update" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={area_cite_url} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update Cites</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => UpdateCites(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Zone</label>
                        <select className={`form-select`} onChange={handleOnChange} value={areaSites.area_city_zone} name='area_city_zone' >
                            <option>Select zone</option>
                            {
                                areaZone.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.area_zone_target}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${areaSites.area_city_zone === "" || areaSites.area_city_zone === "Select territory" || areaSites.area_city_zone === null ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Name</label>
                        <input onChange={handleOnChange} name='area_city_name' value={areaSites.area_city_name} type="text" className="form-control" placeholder="" />
                        <small className={`${areaSites.area_city_name === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Description </label>
                        <textarea onChange={handleOnChange} className={`form-control`} name='area_city_description' value={areaSites.area_city_description} rows={3} defaultValue={""} />
                        <small className={`${areaSites.area_city_description === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate(area_cite_url) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
