import React, { Fragment, useEffect, useState } from 'react';
import { MdEdit, MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesSettingsTypeID } from '../../../../../services/api/ServicesSettings';
import { SpinnerData } from '../../../../../components/SpinnerData'; 
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../../components/Button';
import { ModelTemplapleSimple } from '../../../../../components/CustomModal';


export const IDType = () => {

    const [idType, setIdType] = useState([]);
    const [totalidType, setTotalidType] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });


    useEffect(() => {
        getTypeId();
    }, []);

    // Get all type id
    const getTypeId = async () => {
        await ServicesSettingsTypeID.get().then((res) => {
            setIdType(res.data.Type_ID);
            setTotalidType(res.data.Total_Number_Of_IDType)
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const deleteTypeId = async (id) => {
        setLoading(true);
        await ServicesSettingsTypeID.delete(id).then((res) => {
            setShowModalDelete(false);
            getTypeId();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };


    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }

    return (
        <>
            <section className='admin-settings-idtype'>
                <div className='grid-custom-one-column'>
                    <div className='custom-btn-header custom-end'>
                        <Link to='/admin/settings/id-type/add' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add ID Type`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Number of ID Type </th>
                                <th scope="col">Name</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${idType.length !== 0 && 'd-none'}`}>
                                <td colSpan={4} className={`${!loadData && "custom-loading-data-td"} custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                </td>
                            </tr>
                            {
                                !loadData && idType.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <tr>
                                            <td>{item.id_country && item.id_country.country_name}</td>
                                            <td>{item.number_typeid && item.number_typeid}</td>
                                            <td>{item.id_name && item.id_name}</td>
                                            <td className="td-action">
                                                <Link to={`/admin/settings/id-type/edit/${item.id_type}`} className='td-link edit'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete' onClick={() => deleteData(item.id_type, item.id_country.country_name)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                )
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Total : </td>
                                <td>{!loadData && totalidType}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteTypeId(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
