import React, { Fragment, useEffect, useState } from 'react';
import { IoChevronBackSharp } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServicesTraningQuizAnswerSections, ServicesTraningSections } from '../../../../../../../../services/api/ServicesTrainings';
import { toast } from 'react-toastify';
import { KCustomButtonDouble } from '../../../../../../../../components/Button';
import { ModelTemplapleSimple } from '../../../../../../../../components/CustomModal';
import { IoMdAdd, IoMdCloseCircle } from 'react-icons/io';
 import { readFileAsBase64, verifyBase64MediaImage, verifyBase64MediaVideo } from '../../../../../../../../components/ExtractDate';

export const AddQuizSections = () => {

    const [showModalAddAnswer, setShowModalAddAnswer] = useState(false);
    const [showModalRemoveAnswer, setShowModalRemoveAnswer] = useState(false);

    const navigate = useNavigate();
    const { id_training, id_section } = useParams();
    const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([]);
    const [errors, setErrors] = useState([]);
    const [errorsJson, setErrorsJson] = useState({
        answer: "",
        number_of_points: "",
    });
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });


    const [newAnswer, setNewAnswer] = useState({
        id_quiz: Number(id_section),
        answer_label: "",
        answer_correct: ""
    });

    const [addQuizeSection, setAddQuizeSection] = useState({
        quiz_section: {
            id_section: Number(id_section),
            quiz_question_name: "",
            quiz_question_points: "",
            quiz_question_type: "",
            quiz_question_media: [],
            quiz_description: "",
        },
        answers: [],
    })

    const handleAddAnswer = () => {
        // Vérifiez que les champs ne sont pas vides
        if (newAnswer.answer_label.trim() === "" || newAnswer.answer_correct === "") {
            // Affichez un message d'erreur ou faites ce que vous préférez
            return;
        }

        // Convertissez la chaîne de caractères en booléen pour answer_correct
        const isCorrect = newAnswer.answer_correct === "true";

        // Ajoutez la nouvelle réponse au tableau answers
        setAddQuizeSection((item) => ({
            ...item,
            answers: [...item.answers, {
                id_quiz: Number(id_section),
                answer_label: newAnswer.answer_label,
                answer_correct: isCorrect
            }]
        }));

        // Réinitialisez l'état de la nouvelle réponse
        setNewAnswer({
            id_quiz: Number(id_section),
            answer_label: "",
            answer_correct: ""
        });

        setShowModalAddAnswer(false);
    };

    // Remove answer response
    const removeAnswer = (index) => {
        setAddQuizeSection((item) => {
            const updatedAnswers = [...item.answers];
            if (index >= 0 && index < updatedAnswers.length) {
                updatedAnswers.splice(index, 1);
            }
            return {
                ...item,
                answers: updatedAnswers
            };
        });
    };

    const handleRemoveAnswer = (index) => {
        removeAnswer(index);
        setShowModalRemoveAnswer(false)
    };


    useEffect(() => {
        getSectionsById();
    }, []);


    // Get id sections
    const getSectionsById = async () => {
        await ServicesTraningSections.getById(id_section).then((res) => {
            setSection(res.data.data);
        }).catch((err) => {
            return err
        })
    };


    const handleOnChange = (e) => {
        const { name, value } = e.target;

        if (name === "answer_label" || name === "answer_correct") {
            // Si l'événement provient des champs du modal, mettez à jour newAnswer
            setNewAnswer({
                ...newAnswer,
                [name]: value
            });
        } else {
            setAddQuizeSection((item) => ({
                ...item,
                quiz_section: {
                    ...item.quiz_section,
                    [name]: value
                }
            }));
        }
    };


    const handleFileOnChange = async (e) => {
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            const fileType = files[i].type.split('/')[0];
            const isImageOrVideo = fileType === 'image' || fileType === 'video';

            if (isImageOrVideo) {
                const base64Data = await readFileAsBase64(files[i]);
                setAddQuizeSection((item) => ({
                    ...item,
                    quiz_section: {
                        ...item.quiz_section,
                        quiz_question_media: [
                            ...item.quiz_section.quiz_question_media,
                            { media_file: base64Data }
                        ]
                    }
                }))
            } else {
                console.error(`Unsupported file type: ${fileType}`);
            }
        }
    };

    // Remove media
    const removeMedia = (index) => {
        setAddQuizeSection((item) => {
            const updatedMedia = [...item.quiz_section.quiz_question_media];
            if (index >= 0 && index < updatedMedia.length) {
                updatedMedia.splice(index, 1);
            } else {
                console.error(`Index ${index} out of bounds.`);
            }
            return {
                ...item,
                quiz_section: {
                    ...item.quiz_section,
                    quiz_question_media: updatedMedia
                }
            };
        });
    };

    const createQuizSection = async (e) => {
        e.preventDefault();
        setLoading(true);

        var data = {
            quiz_section: {
                id_section: Number(id_section),
                quiz_question_name: addQuizeSection.quiz_section.quiz_question_name,
                quiz_question_points: Number(addQuizeSection.quiz_section.quiz_question_points),
                quiz_question_type: addQuizeSection.quiz_section.quiz_question_type,
                quiz_question_media: addQuizeSection.quiz_section.quiz_question_media,
                quiz_description: addQuizeSection.quiz_section.quiz_description
            },
            answers: addQuizeSection.answers
        }
  
        await ServicesTraningQuizAnswerSections.post(JSON.stringify(data)).then((res) => {
            navigate(`/admin/trainings/${id_training}/section/${id_section }/quiz`);
            toast.success("section and quiz create with successfull");
        }).catch((err) => { 
            setErrors(err.response.data);
            toast.error("Tous les champs sont requis");
        });

        setLoading(false);
    };


    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalRemoveAnswer(true);
    }

    return (
        <>
            <section id="admin-section-forms-add" className='admin-traning-form-all'>
                <div className='cusom-go-back-page mb-3'>
                    <Link to={`/admin/trainings/${id_training}/section/${id_section}'/quiz`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add new quiz</h4>
                    </div>
                </div>

                <form className='custom-form' onSubmit={(e) => createQuizSection(e)}>
                    <div className='body-formulaire'>
                        <div className="mb-3">
                            <label className="form-label"> Name : </label>
                            <input type="text" className={`form-control`} onChange={handleOnChange} name='quiz_question_name' />
                            <small className={`${errors.quiz_question_name && (errors.quiz_question_name[0] || addQuizeSection.quiz_section.quiz_question_name === '') ? "errors-fields" : "d-none"}`}>{errors.quiz_question_name}</small>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="quiz_description" className="form-label"> Description : </label>
                            <textarea id="quiz_description" className={`form-control`} onChange={handleOnChange} name="quiz_description" rows="4"></textarea>
                            <small className={`${errors.quiz_description && (errors.quiz_description[0] || addQuizeSection.quiz_section.quiz_description === '') ? "errors-fields" : "d-none"}`}>{errors.quiz_description}</small>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="quiz_question_points" className="form-label"> Number of points </label>
                            <input type="number" onChange={handleOnChange} name='quiz_question_points' className={`form-control`} id="quiz_question_points" />
                            <small className={`${addQuizeSection.quiz_section.quiz_question_points === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Question Type </label>
                            <select className={`form-select`} onChange={handleOnChange} name='quiz_question_type'>
                                <option>Select type</option>
                                <option value={`multiple`}>Multiple choice</option>
                                <option value={`unique`}>Unique answer</option>
                            </select>
                            <small className={`${addQuizeSection.quiz_section.quiz_question_type === "" || addQuizeSection.quiz_section.quiz_question_type === "Select type" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="quiz_question_media" className="form-label">Add media </label>
                            <input onChange={handleFileOnChange} multiple type="file" className={`form-control`} id="quiz_question_media" />
                            <small className={`${addQuizeSection.quiz_section.quiz_question_media === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                        <div className='custom-display-media-without-description'>
                            {
                                addQuizeSection.quiz_section && addQuizeSection.quiz_section.quiz_question_media.map((item, index) => (
                                    // <Fragment key={index}>
                                    //     <div className='content'>
                                    //         <IoMdCloseCircle className='icon-close' onClick={() => removeMedia(index)} />
                                    //         <img src={item.media_file} alt="Base64 Images" onClick={() => console.log(index)} />
                                    //     </div>
                                    // </Fragment>
                                    <Fragment key={index}> 
                                    <div className='content'>
                                        <IoMdCloseCircle className='icon-close' onClick={() => removeMedia(index)} /> 
                                        {
                                            verifyBase64MediaImage(item.media_file) && (
                                                <>
                                                    <img src={`${item.media_file}`} title='Cliquer pour cacher la description' alt="Base64 Images" onClick={() => {}} />
                                                </>
                                            )
                                        }
                                        {
                                            verifyBase64MediaVideo(item.media_file) && (
                                                <>

                                                    <video controls>
                                                        <source src={`${item.media_file}`} />
                                                        Votre navigateur ne supporte pas la balise vidéo.
                                                    </video>
                                                </>
                                            )
                                        }
                                    </div>
                                </Fragment>
                                ))
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Section </label>
                            <input value={section.sections_name} type="text" className={`form-control`} disabled />
                        </div>
                        <div className="my-4 align-items-center">
                            <span className='header-choise d-block pb-2' style={{ fontSize: "1.1rem", color: "black" }}>Option de réponses </span>
                        </div>
                        <div className="content-option-answers">
                            {
                                addQuizeSection.answers.map((item, index) => (
                                    <Fragment key={index}>
                                        <span className={`${item.answer_correct ? 'correct' : 'error'} answer`} onClick={() => deleteData(index, item.answer_label)}>{item.answer_label} </span>
                                    </Fragment>
                                ))
                            }
                        </div>
                        <Link to="#" className='link-add-option' onClick={() => setShowModalAddAnswer(true)}>
                            <span className=''>
                                <IoMdAdd size={15} className='icon' />
                            </span>
                            <span className='description-btn ms-3'>Add a new response option</span>
                        </Link>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate(`/admin/trainings/${id_training}/section/${id_section}/quiz`) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>


            {/* Add answer option */}
            <ModelTemplapleSimple icon={true} show={showModalAddAnswer} hide={() => { setShowModalAddAnswer(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal traning-custom-add-answer'>
                        <div className='content-body'>
                            <h5 className='header'>Add answer</h5>
                            <form className=''>
                                <div className="">
                                    <label htmlFor="chapter_name" className="form-label"> Name </label>
                                    <input type="text" className={`form-control`} onChange={handleOnChange} name='answer_label' />
                                    <small className={`${newAnswer.answer_label === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                                </div>
                                <div className="">
                                    <label className="form-label">Correct  </label>
                                    <select className={`form-select`} onChange={handleOnChange} name='answer_correct'>
                                        <option>Select answer true or false</option>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                    <small className={`${newAnswer.answer_correct === '' || newAnswer.answer_correct === 'Select answer true or false' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                                </div>
                            </form>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalAddAnswer(false) }}
                            clickr={handleAddAnswer}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-training-add-optin-answer'} />

            {/* Remove answer option */}
            <ModelTemplapleSimple icon={true} show={showModalRemoveAnswer} hide={() => { setShowModalRemoveAnswer(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalRemoveAnswer(false) }}
                            clickr={() => handleRemoveAnswer(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
