import React, { Fragment, useEffect, useState } from 'react';
import { MdEdit, MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesUsers } from '../../../../services/api/ServicesUsers';
import { SpinnerData } from './../../../../components/SpinnerData'; 
import { userEmpty } from '../../../../assets/images/ExportsImages';
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../components/Button';
import { ModelTemplapleSimple } from '../../../../components/CustomModal';

export const Users = () => {

    const [users, setUsers] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });

    useEffect(() => {
        document.title = "Users";
    });

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        await ServicesUsers.get().then((res) => {
            console.log("DATA", res.data.User);
            setUsers(res.data.User);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const deleteUsers = async (id) => {
        setLoading(true);
        await ServicesUsers.delete(id).then((res) => {
            setShowModalDelete(false);
            getUsers();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }

    return (
        <>
            <section id='admin-users'>
                <div className='grid-custom-one-column'>
                    <h4 className='title'>Users list</h4>
                    <div className='custom-btn-header custom-end'>
                        <Link to='/admin/users/add' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add user`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Clients</th>
                                <th scope="col">Country</th>
                                <th scope="col">FullName</th>
                                <th scope="col">identifiants</th>
                                <th scope="col">Email</th>
                                <th scope="col">Privilèges</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={7} className={`${users.length !== 0 && 'd-none'}  custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && users.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td>
                            </tr>
                            {
                                !loadData && users.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <tr>
                                            <td>{item.the_client && item.the_client.client_name}</td>
                                            <td>{item.country && item.country.country_name}</td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <span>
                                                        <img src={userEmpty} width={30} height={30} alt='artel' />
                                                    </span>
                                                    <span className='ms-2' >{item.nom !== null || item.nom !== undefined || item.nom !== "" ? item.nom : ""}</span>
                                                </div>
                                            </td>
                                            <td>{item.username && item.username}</td>
                                            <td>{item.email !== null || item.email !== undefined || item.email !== "" ? item.email : ""}</td>
                                            <td>{item.role && item.role.role_name}</td>

                                            <td className="td-action">
                                                <Link to={`/admin/users/edit/${item.id}`} className='td-link edit'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete' onClick={() => deleteData(item.id, item.the_client.client_name)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteUsers(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
