import React, { Fragment } from 'react';
import { MenuContentBolderLighter } from '../../../../components/sidebar/SideBar';



export const MenuNavBarSettings = () => {

    const url = "/admin/settings/";

    const menu = [
        {
            title: "countries",
            path: "country",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Localities",
            path: "locality",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Level studies",
            path: "level-of-study",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "ID Type",
            path: "id-type",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Privileges",
            path: "privilege",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Roles",
            path: "role",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Industries",
            path: "industry",
            sub_navbar: null,
            location_path: url,
        },
    ];

    return (
        <>
            <nav id='menu-setting'>
                <MenuContentBolderLighter fontionnality={`Traning`} lighter={true} bolder={false} menu={menu} />
            </nav>
        </>
    );
};