import React, { Fragment, useEffect, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ServicesFootSoldiersKYC } from '../../../../services/api/ServicesFieldSoldiers';
import { toast } from 'react-toastify';
import { SpinnerData, SpinnerDataSmall } from '../../../../components/SpinnerData';
import { IoChevronBackSharp } from 'react-icons/io5'; 
import { KCustomButtonDouble } from '../../../../components/Button';
import { ModelTemplapleSimple } from '../../../../components/CustomModal';

export const PendingKYC = () => {

    const [footSoldierPending, setFootSoldierPending] = useState([]);
    const [kycAgence, setKycAgence] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });


    useEffect(() => {
        document.title = "Foot-Soldier/pending";
    });

    useEffect(() => {
        getFootSoldiersPending();
    }, []);

    const getFootSoldiersPending = async () => {
        await ServicesFootSoldiersKYC.get().then((res) => {
            setFootSoldierPending(res.data.Kyc);
            setKycAgence(res.data.count_kyc);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const extractDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();

        // Create a date string in YYYY-MM-DD format
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return formattedDate;
    };

    const extractTime = (dateString) => {
        const dateObject = new Date(dateString);
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        const seconds = dateObject.getSeconds();

        // If hours exceed 24, add 1 day
        const adjustedHours = hours >= 24 ? hours % 24 : hours;

        // Create a time string in HH:MM:SS format
        const formattedTime = `${adjustedHours < 10 ? '0' : ''}${adjustedHours}H:${minutes < 10 ? '0' : ''}${minutes}min:${seconds < 10 ? '0' : ''}${seconds}s`;

        return formattedTime;
    };

    const deletePpendingKYC = async (id) => {
        setLoading(true);
        await ServicesFootSoldiersKYC.delete(id).then((res) => {
            setShowModalDelete(false);
            getFootSoldiersPending();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
            return err;
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }


    return (
        <>
            <section id='admin-fs-pending' className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/foot-soldiers`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>KYC requests pending  {(!loadData && footSoldierPending !== 0) ? <>({loadData ? <SpinnerDataSmall /> : kycAgence})</> : <></>} </h4>
                    </div>
                </div>
                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Clients</th>
                                <th scope="col">Full Name</th>
                                <th scope="col">Submission date</th>
                                <th scope="col">SLA</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${footSoldierPending.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={`custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && footSoldierPending.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td>
                            </tr>
                            {
                                !loadData && footSoldierPending.map((item, index) => (
                                    <Fragment key={`unique-${index}`}>
                                        <tr>
                                            <td>{((item.kycAgentid && item.kycAgentid.country) && item.kycAgentid.country.country_name) && item.kycAgentid.country.country_name}</td>
                                            <td>{((item.kycAgentid && item.kycAgentid.the_client) && item.kycAgentid.the_client.client_name) && item.kycAgentid.the_client.client_name}</td>
                                            <td>{(item.kycAgentid && item.kycAgentid.nom) && item.kycAgentid.nom + (item.kycAgentid && item.kycAgentid.prenoms) && item.kycAgentid.prenoms}</td>
                                            <td>{extractDate(item.createdAt)}</td>
                                            <td>{extractTime(item.createdAt)}</td>
                                            <td className="td-action">
                                                <Link to={`/admin/foot-soldiers/validate/${item.id}`} className='td-link edit'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete' onClick={() => deleteData(item.id, item.kycAgentid.prenoms)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deletePpendingKYC(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};

