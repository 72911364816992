import React, { Fragment, useEffect, useState } from 'react';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import { IoChevronBackSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { ServicesActionType, ServicesCampaigns, ServicesModelCampaign, ServicesTypeCampaign } from '../../../../../services/api/ServocesCampaigns';
import { ServicesProducts } from '../../../../../services/api/ServicesProducts';
import { IoIosClose } from 'react-icons/io';
import { campaign_url } from './Campaigns';
import { ServicesTerritories } from '../../../../../services/api/ServicesAera';
import { ServicesClients } from '../../../../../services/api/ServicesClients';
import { ServicesSettingsCounties } from '../../../../../services/api/ServicesSettings';

export const CreateCampaigns = () => {

    const [countries, setCountries] = useState([]);
    const [products, setProducts] = useState([]);
    const [client, setClient] = useState([]);
    const [campaignActionType, setCampaignActionType] = useState([]);
    const [campaignTypes, setCampaignTypes] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [territories, setTerritories] = useState([]);
    const [campaignModel, setCampaignModel] = useState([]);
    const [errors, setErrors] = useState([]);
    const [campaign, setCampaign] = useState({
        product: [], // id product type number
        name: '',
        campaign_types: [],
        campaign_actions_types: [],
        campaign_models: [],
        input_description: '',
        process_operationnel: '',
        start_date: '',
        end_date: '',
        client: '',
        country: '',
        illustrative_examples: '',
        territory: [] // id territory type number
    });


    useEffect(() => {
        document.title = "Campaign/add";
    });

    useEffect(() => {
        getProduct();
        getTerritory();
        getCampaignsTypes();
        getCampaignActionType();
        getCampaignModel();
        getClients();
        getCountries();
    }, []);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'product') {
            if (!campaign.product.includes(value)) {
                setCampaign({
                    ...campaign,
                    product: [...campaign.product, Number(value)]
                });
            }
        } else if (name === 'territory') {
            if (!campaign.territory.includes(value)) {
                setCampaign({
                    ...campaign,
                    territory: [...campaign.territory, Number(value)]
                });
            }
        } else if (name === 'campaign_types') {
            if (!campaign.campaign_types.includes(value)) {
                setCampaign({
                    ...campaign,
                    campaign_types: [...campaign.campaign_types, Number(value)]
                });
            }
        } else if (name === 'campaign_actions_types') {
            if (!campaign.campaign_actions_types.includes(value)) {
                setCampaign({
                    ...campaign,
                    campaign_actions_types: [...campaign.campaign_actions_types, Number(value)]
                });
            }
        } else if (name === 'campaign_models') {
            if (!campaign.campaign_models.includes(value)) {
                setCampaign({
                    ...campaign,
                    campaign_models: [...campaign.campaign_models, Number(value)]
                });
            }
        } else {

            setCampaign({
                ...campaign,
                [name]: value
            });
        }

    }

    const removeProducts = (value) => {
        setCampaign({
            ...campaign,
            product: campaign.product.filter(item => item !== value)
        });
    };

    const removeTerritory = (value) => {
        setCampaign({
            ...campaign,
            territory: campaign.territory.filter(item => item !== value)
        });
    };

    const removeCampaignType = (value) => {
        setCampaign({
            ...campaign,
            campaign_types: campaign.campaign_types.filter(item => item !== value)
        });
    };

    const removeActionType = (value) => {
        setCampaign({
            ...campaign,
            campaign_actions_types: campaign.campaign_actions_types.filter(item => item !== value)
        });
    };

    const removeCampaignModels = (value) => {
        setCampaign({
            ...campaign,
            campaign_models: campaign.campaign_models.filter(item => item !== value)
        });
    };

    // Get all products
    const getProduct = async () => {
        await ServicesProducts.get().then((res) => {
            setProducts(res.data.Products);
        }).catch((err) => {
            return err
        })
    };

    // Get all Territories 
    const getTerritory = async () => {
        await ServicesTerritories.get().then((res) => {
            setTerritories(res.data.data);
        }).catch((err) => {
            return err
        })
    };

    // Get all campaign types
    const getCampaignsTypes = async () => {
        await ServicesTypeCampaign.get().then((res) => {
            setCampaignTypes(res.data.CampaignTypes);
        }).catch((err) => {
            return err
        })
    };

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };

    // Get all campaign model
    const getCampaignModel = async () => {
        await ServicesModelCampaign.get().then((res) => {
            setCampaignModel(res.data.CampaignModels);
        }).catch((err) => {
            return err
        })
    };

    // Get all campaign action type
    const getCampaignActionType = async () => {
        await ServicesActionType.get().then((res) => {
            setCampaignActionType(res.data.CampaignActionTypes);
        }).catch((err) => {
            return err
        })
    };

    // Get all countries
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };


    const createCampaign = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log("DATA", campaign);

        await ServicesCampaigns.post(campaign).then((res) => {
            navigate(campaign_url);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });
        setLoading(false);
    };

    return (
        <>
            <section id="admin-campaign-create" className='comapaign-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={campaign_url} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add  campaign</h4>
                    </div>
                </div>
                <form onSubmit={(e) => createCampaign(e)} className='custom-form'>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Name </label>
                        <input onChange={handleOnChange} name='name' type="text" className="form-control" placeholder="" />
                        <small className={`${campaign.name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client</label>
                        <select className={`form-select`} onChange={handleOnChange} value={campaign.client} name='client' >
                            <option>Select client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.client === "Select client" || campaign.client === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Countries</label>
                        <select className={`form-select`} onChange={handleOnChange} value={campaign.country} name='country' >
                            <option>Select countries</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.country === "Select countries" || campaign.country === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Campaing Model</label>
                        <select className={`form-select`} onChange={handleOnChange} value={campaign.campaign_models} name='campaign_models' >
                            <option>Select campaing model</option>
                            {
                                campaignModel.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.campaign_models === "Select campaing model" || campaign.campaign_models.length === 0 ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-2">
                        <div className='item-selected'>
                            {campaignModel
                                .filter(item => campaign.campaign_models.includes(item.id)).map((item, index) => (
                                    <Fragment key={index}>
                                        <span>{item.name} <IoIosClose title='Cliquer pour supprimer' onClick={() => removeCampaignModels(item.id)} className='icon' /></span>
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Campaing type</label>
                        <select className={`form-select`} onChange={handleOnChange} value={campaign.campaign_types} name='campaign_types' >
                            <option>Select campaing type</option>
                            {
                                campaignTypes.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.campaign_types === "Select campaing type" || campaign.campaign_types.length === 0 ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-2">
                        <div className='item-selected'>
                            {campaignTypes
                                .filter(item => campaign.campaign_types.includes(item.id)).map((item, index) => (
                                    <Fragment key={index}>
                                        <span>{item.name} <IoIosClose title='Cliquer pour supprimer' onClick={() => removeCampaignType(item.id)} className='icon' /></span>
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Action type</label>
                        <select className={`form-select`} onChange={handleOnChange} value={campaign.campaign_actions_types} name='campaign_actions_types' >
                            <option>Select action type</option>
                            {
                                campaignActionType.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.campaign_actions_types === "Select action type" || campaign.campaign_actions_types.length === 0 ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-2">
                        <div className='item-selected'>
                            {campaignActionType
                                .filter(item => campaign.campaign_actions_types.includes(item.id)).map((item, index) => (
                                    <Fragment key={index}>
                                        <span>{item.name} <IoIosClose title='Cliquer pour supprimer' onClick={() => removeActionType(item.id)} className='icon' /></span>
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Description </label>
                        <textarea className={`form-control`} onChange={handleOnChange} name="input_description" rows="5" ></textarea>
                        <small className={`${campaign.input_description === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Product</label>
                        <select className={`form-select`} onChange={handleOnChange} name='product' >
                            <option>Select a product</option>
                            {
                                products.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_product}>{item.product_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.product.length === 0 || campaign.product === "Select a product" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-2">
                        <div className='item-selected'>
                            {products
                                .filter(item => campaign.product.includes(item.id_product)).map((item, index) => (
                                    <Fragment key={index}>
                                        <span>{item.product_name} <IoIosClose title='Cliquer pour supprimer' onClick={() => removeProducts(item.id_product)} className='icon' /></span>
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Territory</label>
                        <select className={`form-select`} onChange={handleOnChange} name='territory' >
                            <option>Select territory</option>
                            {
                                territories.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.area_territory_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.territory.length === 0 || campaign.territory === "Select territory" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-2">
                        <div className='item-selected'>
                            {territories
                                .filter(item => campaign.territory.includes(item.id)).map((item, index) => (
                                    <Fragment key={index}>
                                        <span>{item.area_territory_name} <IoIosClose title='Cliquer pour supprimer' onClick={() => removeTerritory(item.id)} className='icon' /></span>
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Exemple d'illustration </label>
                        <textarea className={`form-control`} onChange={handleOnChange} name="illustrative_examples" rows="5" ></textarea>
                        <small className={`${campaign.illustrative_examples === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Start date </label>
                        <input type="date" className="form-control" onChange={handleOnChange} name='start_date' placeholder="" />
                        <small className={`${campaign.start_date === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> End date </label>
                        <input type="date" className="form-control" onChange={handleOnChange} name='end_date' placeholder="" />
                        <small className={`${campaign.end_date === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate(campaign_url) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
