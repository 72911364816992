import React, { Fragment, useEffect, useState } from 'react';
import { ServicesSettinPrivileges, ServicesSettingRoles } from '../../../../../services/api/ServicesSettings';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
 import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const EditRole = () => {


    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [privileges, setPrivileges] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const { id_role } = useParams();

    const [rolesUpdate, setRolesUpdate] = useState({
        privileges: "",
        role_name: ""
    })

    useEffect(() => {
        getPrivilegesById();
        getPrivileges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChange = (e) => {
        setRolesUpdate({
            ...rolesUpdate,
            [e.target.name]: e.target.value
        });
    }


    // Get all privilege
    const getPrivileges = async () => {
        await ServicesSettinPrivileges.get().then((res) => {
            setPrivileges(res.data.Privilege);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const getPrivilegesById = async () => {
        await ServicesSettingRoles.getById(id_role).then((res) => {
            const resData = res.data.data;
            setRolesUpdate({
                privileges: resData.privileges,
                role_name: resData.role_name
            });
        }).catch((err) => {
            return err
        });
    }

    const handleRoleChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions).map((option) => Number(option.value));
        for (let index = 0, length = rolesUpdate.privileges.length; index < length; index += 1) {
            const element = rolesUpdate.privileges[index];
            selectedOptions.push(element.id) 
        } 
        setSelectedRoles(selectedOptions);
    };

    const updateRoles = async (e) => {
        e.preventDefault();

        setLoading(true);
        var data = {
            id: id_role,
            privileges: selectedRoles,
            role_name: rolesUpdate.role_name,
        }

        await ServicesSettingRoles.put(id_role, JSON.stringify(data)).then((res) => {
            navigate('/admin/settings/role');
            toast.success("privilege update with successfull")
        }).catch((err) => {
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section className="admin-forms"> 
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/role`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update role</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => updateRoles(e)}>
                    <div className="mb-3">
                        <label htmlFor="role_name" className="form-label">Name  :</label>
                        <input type="text" onChange={handleOnChange} value={rolesUpdate.role_name} name='role_name' className={`form-control`} id="role_name" />
                        <small className={`${rolesUpdate.role_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="country" className="form-label">Roles :</label>
                        <div className='d-flex flex-column w-100'>
                            <select multiple className={`form-select`} onChange={handleRoleChange} name='privileges' aria-label="Default select example">
                                {
                                    privileges.map((item, index) =>
                                        <Fragment key={`unique-${index}`}>
                                            <option className='' value={item.id}>{item.name}</option>
                                        </Fragment>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/role") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section> 
        </>
    );
};
