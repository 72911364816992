import React from 'react';
import { Outlet } from 'react-router-dom';
import { MenuContentBolderLighter } from '../../../../components/sidebar/SideBar';

export const CampaignLayouts = () => {
    
    const url = "/admin/campaign/";

    const menu = [
        {
            title: "Campaign",
            path: "list",
            sub_navbar: null,
            location_path: url,
        }, 
        {
            title: "Type of campaign",
            path: "type",
            sub_navbar: null,
            location_path: url,
        }, 
        {
            title: "Model of campaign",
            path: "model",
            sub_navbar: null,
            location_path: url,
        }, 
        {
            title: "Action type",
            path: "action-type",
            sub_navbar: null,
            location_path: url,
        }, 
    ];

    return (
        <>

            <section id='admin-campaign-layouts'>
                <nav className='menu-campaign'>
                    <MenuContentBolderLighter fontionnality={`Traning`} lighter={true} bolder={false} menu={menu} />
                </nav>
                <Outlet />
            </section>
        </>
    );
};
