import { BASE_URL } from "../BASE_URL";

export class ServicesSMS {

    static url_sms = 'send-message-api-apk/';

    static send = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_sms, data, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    // 'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }
}