import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Campaigns } from '../views/campaign/Campaigns';
import { CreateCampaigns } from '../views/campaign/Create';
import { UpdateCampaigns } from '../views/campaign/Update';
import { CampaignLayouts } from '../views/CampaignLayouts';
import { CampaignTypes } from '../views/campaign-types/CampaignTypes';
import { CreateCampaignTypes } from '../views/campaign-types/Create';
import { UpdateCampaignTypes } from '../views/campaign-types/Update';
import { ModelCampaigns } from '../views/model-campaign/ModelCampaigns';
import { CreateModelCampaign } from '../views/model-campaign/Create';
import { UpdateModelCampaign } from '../views/model-campaign/Update';
import { TypeActions } from '../views/type-action/TypeActions';
import { CreateTypeAction } from '../views/type-action/Create';
import { UpdateTypeAction } from '../views/type-action/Update';


export const CampaignControllers = () => {
    return (
        <>
            <Routes>
                <Route exact element={<CampaignLayouts />} >
                    <Route exact path='list/'  >
                        <Route exact path='' element={<Campaigns />} />
                        <Route exact path='create' element={<CreateCampaigns />} />
                        <Route exact path='update/:id_campaign' element={<UpdateCampaigns />} />
                    </Route>
                    <Route exact path='type/'>
                        <Route exact path='' element={<CampaignTypes />} />
                        <Route exact path='create' element={<CreateCampaignTypes />} />
                        <Route exact path='update/:id_campaign_type' element={<UpdateCampaignTypes />} />
                    </Route>
                    <Route exact path='model/'>
                        <Route exact path='' element={<ModelCampaigns />} />
                        <Route exact path='create' element={<CreateModelCampaign />} />
                        <Route exact path='update/:id_campaign_model' element={<UpdateModelCampaign />} />
                    </Route>
                    <Route exact path='action-type/'>
                        <Route exact path='' element={<TypeActions />} />
                        <Route exact path='create' element={<CreateTypeAction />} />
                        <Route exact path='update/:id_campaign_type_action' element={<UpdateTypeAction />} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
};
