import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesPOS } from '../../../../services/api/ServicesPOS';
import { ServicesSettingsCounties } from '../../../../services/api/ServicesSettings';
import { ServicesClients } from '../../../../services/api/ServicesClients';
import { KCustomButtonDouble } from '../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const CreatePos = () => {

    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState([]);
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [POSPost, setPOSPost] = useState({
        pos_countrie: "",
        pos_client: "",
        sheet_pos: "",
    })

    const handleOnChange = (e) => {
        setPOSPost({
            ...POSPost,
            [e.target.name]: e.target.value
        });
    }

    const handleFileOnChange = (e) => {
        setPOSPost({
            ...POSPost,
            [e.target.name]: e.target.files[0]
        });
    }

    useEffect(() => {
        getCountries();
        getClients();
    }, []);

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };


    const createPOS = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('pos_countrie', POSPost.pos_countrie)
        forms.append('pos_client', POSPost.pos_client)
        forms.append('sheet_pos', POSPost.sheet_pos)

        await ServicesPOS.post(forms).then((res) => {
            navigate('/admin/pos');
            var success = `${res.data.message} Delete ${res.data.deleted_entries} Update ${res.data.updated_entries} Create ${res.data.created_entries}`;
            toast.success(success)
        }).catch((err) => {
            setErrors(err.response.data)
            toast.error(err.response.data);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="admin-pos-create" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/pos`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update POS Base</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => createPOS(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} onChange={handleOnChange} name='pos_client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.pos_client && (POSPost.pos_client === '' || POSPost.pos_client === "Selected client") ? "errors-fields" : "d-none"}`}>{errors.pos_client}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='pos_countrie' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.pos_countrie && (POSPost.pos_countrie === "" || POSPost.pos_countrie === "Selected country") ? "errors-fields" : "d-none"}`}>{errors.pos_countrie}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="sheet_pos" className="form-label"> Excel file : </label>
                        <input type="file" name='sheet_pos' onChange={handleFileOnChange} className={`form-control`} id="sheet_pos" />
                        <small className={`${errors.sheet_pos || POSPost.sheet_pos === '' ? "errors-fields" : "d-none"}`}>{errors.sheet_pos}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/pos") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    )
}
