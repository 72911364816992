import React, { Fragment, useEffect, useState } from 'react';
import { ServicesActionType } from '../../../../../services/api/ServocesCampaigns';
import { campaign_types_action_url } from './TypeActions';
import { toast } from 'react-toastify';
import { ServicesClients } from '../../../../../services/api/ServicesClients';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const UpdateTypeAction = () => {
    
    const { id_campaign_type_action } = useParams();
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState([]);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [campaignTypeAction, setCampaignTypeAction] = useState({
        id: id_campaign_type_action,
        description: "",
        name: "",
        client: null, // id client
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setCampaignTypeAction({
            ...campaignTypeAction,
            [name]: value
        });
    }

    useEffect(() => {
        document.title = "Campaign - ACtion Type";
        getClients();
        getCampaignTypeActionById();
    }, []);

    const getCampaignTypeActionById = async () => {
        await ServicesActionType.detail(id_campaign_type_action).then((res) => {
            const data = res.data.CampaignActionTypes;
            console.log(data);
            
            setCampaignTypeAction({
                // client: data.client && data.client, // id client
                client: null, // id client
                name: data.name && data.name,
                description: data.description && data.description, 
            });
        }).catch((err) => {
            return err
        });
    }

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };

    const UpdateCampaignTypeAction = async (e) => {
        e.preventDefault();

        setLoading(true); 

        await ServicesActionType.update(id_campaign_type_action, JSON.stringify(campaignTypeAction)).then((res) => {
            navigate(campaign_types_action_url);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="admin-campaign-type-action-update" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={campaign_types_action_url} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update Type Action</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => UpdateCampaignTypeAction(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client</label>
                        <select className={`form-select`} onChange={handleOnChange} value={campaignTypeAction.client} name='roles' >
                            <option>Select client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaignTypeAction.client === "" || campaignTypeAction.client === "Select client" || campaignTypeAction.client === null ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Name</label>
                        <input onChange={handleOnChange} name='name' value={campaignTypeAction.name} type="text" className="form-control" placeholder="" />
                        <small className={`${campaignTypeAction.name === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Description </label>
                        <textarea onChange={handleOnChange} className={`form-control`} name='description' value={campaignTypeAction.description} rows={3} defaultValue={""} />
                        <small className={`${campaignTypeAction.description === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate(campaign_types_action_url) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>   
        </>
    );
};
 