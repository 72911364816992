import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

export const SpinnerData = () => {
    return (
        <>
            <RotatingLines
                strokeColor="#DD3563"
                strokeWidth="1.5"
                animationDuration="0.70"
                width="25"
                visible={true}
            />
        </>
    );
};

export const SpinnerDataSmall = () => {
    return (
        <>
            <RotatingLines
                strokeColor="#DD3563"
                strokeWidth="1.5"
                animationDuration="0.70"
                width="20"
                visible={true}
            />
        </>
    );
};