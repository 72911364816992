import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesSettingsCounties, ServicesSettingsLevelOfStudy } from '../../../../../services/api/ServicesSettings';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const AddLevelOfStudies = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState(false);
    const [levelOfStudyPost, setLevelOfStudyPost] = useState({
        level_name: "",
        id_country: "",
    })

    const handleOnChange = (e) => {

        let optionItems = e.target.value;

        setLevelOfStudyPost({
            ...levelOfStudyPost,
            [e.target.name]: e.target.value
        });

        if (optionItems === 'Other') {
            setSelectedLevel(true);
        }

        if (optionItems === 'BAC' || optionItems === 'Licence' || optionItems === 'Maitrise') {
            setSelectedLevel(false);
        }
    }

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    useEffect(() => {
        getCountries();
    }, [levelOfStudyPost]);

    const createLevelOfStudent = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('level_name', levelOfStudyPost.level_name)
        forms.append('id_country', levelOfStudyPost.id_country)

        await ServicesSettingsLevelOfStudy.post(forms).then((res) => {
            navigate('/admin/settings/level-of-study');
            toast.success(res.data.message)
        }).catch((err) => {
            toast.error(err.response.data.message);
            setErrors(err.response.data.errors);
        });

        setLoading(false);
    };


    return (
        <>
            <section className="admin-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/level-of-study`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add level of study</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => createLevelOfStudent(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='id_country'>
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${levelOfStudyPost.id_country === "" || levelOfStudyPost.id_country === "Selected country" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password2" className="form-label">Level  </label>
                        <div className='d-flex flex-column w-100'>
                            <select className={`form-select`} onChange={handleOnChange} name={!selectedLevel && `level_name`}>
                                <option>Selected level</option>
                                <option value='CEPE'>CEPE</option>
                                <option value='BEPEC'>BEPEC</option>
                                <option value='BAC'>BAC</option>
                                <option value='Licence'>Licence</option>
                                <option value='Maitrise'>Maitrise</option>
                                <option value='Other'>Other</option>
                            </select>
                            <small className={`${levelOfStudyPost.level_name === "" || levelOfStudyPost.level_name === "Selected level" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                    </div>
                    {
                        selectedLevel && (
                            <div className="mb-3">
                                <label htmlFor="level" className="form-label">Other :</label>
                                <input type="text" onChange={handleOnChange} name='level_name' className={`form-control`} id="level" autocomplete="off" />
                                <small className={`${levelOfStudyPost.level_name === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                            </div>
                        )
                    }
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/level-of-study") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
