import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServicesModelCampaign } from '../../../../../services/api/ServocesCampaigns';
import { Link, useNavigate } from 'react-router-dom';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';
import { campaign_model_url } from './ModelCampaigns';
import { ServicesClients } from '../../../../../services/api/ServicesClients';

export const CreateModelCampaign = () => {

    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState([]);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [campaignModel, setCampaignModel] = useState({
        description: "",
        name: "",
        client: null, // id client
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setCampaignModel({
            ...campaignModel,
            [name]: value
        });
    }

    useEffect(() => {
        document.title = "Campaign - Model";
        getClients();
    }, []);

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };

    const CreateModelCampaign = async (e) => {
        e.preventDefault();

        setLoading(true); 

        await ServicesModelCampaign.create(JSON.stringify(campaignModel)).then((res) => {
            navigate(campaign_model_url);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="admin-campaign-model-create" className='admin-forms'>
                <div className='cusom-go-back-page'>
                    <Link to={campaign_model_url} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add Model Campaign</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => CreateModelCampaign(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client</label>
                        <select className={`form-select`} onChange={handleOnChange} value={campaignModel.client} name='client' >
                            <option>Select client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaignModel.client === "" || campaignModel.client === "Select client" || campaignModel.client === null ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Name</label>
                        <input onChange={handleOnChange} name='name' value={campaignModel.name} type="text" className="form-control" placeholder="" />
                        <small className={`${campaignModel.name === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Description </label>
                        <textarea onChange={handleOnChange} className={`form-control`} name='description' value={campaignModel.description} rows={3} defaultValue={""} />
                        <small className={`${campaignModel.description === "" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate(campaign_model_url) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
